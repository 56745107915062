// Getters
export const GETTER_EXAMINEE_DETAIL_PAGE: string = 'examineeDetailPage/GETTER_EXAMINEE_DETAIL_PAGE';
export const GETTER_EXAMINEE_DETAIL_PAGE_GET_TESTER_DETAIL: string =
  'testerDetailPage/GETTER_EXAMINEE_DETAIL_PAGE_GET_TESTER_DETAIL';

// Mutations
export const MUTATION_EXAMINEE_DETAIL_PAGE_SET_TESTER_DETAIL: string =
  'examineeDetailPage/MUTATION_EXAMINEE_DETAIL_PAGE_SET_TESTER_DETAIL';
export const MUTATION_EXAMINEE_DETAIL_PAGE_SWITCH_TEST_PASS: string =
  'examineeDetailPage/MUTATION_EXAMINEE_DETAIL_PAGE_SWITCH_TEST_PASS';
export const MUTATION_EXAMINEE_DETAIL_PAGE_GET_TEST_PASS: string =
  'examineeDetailPage/MUTATION_EXAMINEE_DETAIL_PAGE_GET_TEST_PASS';

// Actions
export const ACTION_EXAMINEE_DETAIL_PAGE_GET_TESTER_DETAIL: string =
  'examineeDetailPage/ACTION_EXAMINEE_DETAIL_PAGE_GET_TESTER_DETAIL';
export const ACTION_EXAMINEE_DETAIL_PAGE_SWITCH_TEST_PASS: string =
  'examineeDetailPage/ACTION_EXAMINEE_DETAIL_PAGE_SWITCH_TEST_PASS';
export const ACTION_EXAMINEE_DETAIL_PAGE_GET_TEST_PASS: string =
  'examineeDetailPage/ACTION_EXAMINEE_DETAIL_PAGE_GET_TEST_PASS';
