import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { ErrorStatus } from '../types/adapters/errorAdapter';
import {
  TesterExaminingTermAdapter,
  TesterExaminingTermRequestType,
  TesterExaminingTermResponseType,
} from '@/store/types/adapters/testerExaminingTermAdapter';
import * as types from '@/store/types/testerExaminingTermType';
import * as errTypes from '@/store/types/errorType';
import { Endpoint } from '@/store/const/endpoint';
import { defaultExaminingTermTemplate } from '@/server/api/v1/examining-term';
import { alertError } from '@/server/api/v1/error-handling';
import { ACTION_COMMON_SET_LOADING } from '@/store/types/commonType';

const state = () => {
  return new TesterExaminingTermAdapter();
};

type TesterExaminingTermState = ReturnType<typeof state>;

const getters: GetterTree<TesterExaminingTermState, TesterExaminingTermState> = {
  [types.GETTER_TESTER_EXAMINING_TERM](state: TesterExaminingTermState) {
    return { ...state };
  },
};

const mutations: MutationTree<TesterExaminingTermState> = {
  [types.MUTATION_TESTER_EXAMINING_TERM](state: TesterExaminingTermState, payload: TesterExaminingTermAdapter) {
    state.templateBody = payload.templateBody;
  },
};

const actions: ActionTree<TesterExaminingTermState, TesterExaminingTermState> = {
  [types.ACTION_TESTER_EXAMINING_TERM](context, request: TesterExaminingTermRequestType) {
    let { templateBody } = defaultExaminingTermTemplate;
    const defaultErrorMessage = '注意事項 (/examining) の取得に失敗しました';
    return new Promise((resolve) => {
      context
        .dispatch(ACTION_COMMON_SET_LOADING, true)
        .then(() => this.$axios.$post(Endpoint.EXAMINING_TERM.url, request))
        .then((response: TesterExaminingTermResponseType) => {
          if (response.status === 200 && typeof response.result.template_body === 'string') {
            templateBody = response.result.template_body;
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.EXAMINING_TERM,
              status: response.status,
              message: response.message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
            throw new Error(defaultErrorMessage);
          }
        })
        .catch((error) => alertError({ error, defaultErrorMessage }))
        .finally(() => {
          context.commit(types.MUTATION_TESTER_EXAMINING_TERM, { templateBody });
          resolve({ templateBody });
          context.dispatch(ACTION_COMMON_SET_LOADING, false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
