












import Vue from 'vue';
import { LanguageEnum } from '@/store/enum/language';
import * as rootTypes from '@/store/types/rootType';
import * as commonTypes from '@/store/types/commonType';

export default Vue.extend({
  name: 'Loader',
  computed: {
    displayLang(): LanguageEnum {
      return this.$store.getters[rootTypes.GETTER_DISPLAY_LANG];
    },
    isLoading(): boolean {
      return this.$store.getters[commonTypes.GETTER_COMMON_IS_LOADING];
    },
    isLoadingAlertVisible(): boolean {
      return this.$store.getters[commonTypes.GETTER_COMMON_IS_LOADING_ALERT_VISIBLE];
    },
    isUploadingAlertVisible(): boolean {
      return this.$store.getters[commonTypes.GETTER_COMMON_IS_UPLOADING_ALERT_VISIBLE];
    },
  },
});
