/**
 * UltraGuardian 連携用
 * https://www.notion.so/Web-3007b774359d49f1ae769ba3fdc43688
 * https://github.com/e-coms/proctor-main/blob/edc1da4bc8944e42e38944745ceb45a65710f49d/srv/services/proctor/application/ultraguardian/checker.js
 */
import type { UGEventType, UGMessage } from '@/types/ultra-guardian';

const defaultSetupPageUrl = 'https://www.remote-testing.jp/ultraguardian/setup.html';

export function isUG(redirect = false, setupPageUrl = defaultSetupPageUrl): boolean {
  if (redirect) redirectToSetupUG(setupPageUrl);
  return window.navigator.userAgent.includes('UltraGuardian');
}

export function redirectToSetupUG(setupPageUrl = defaultSetupPageUrl) {
  if (!isUG()) location.href = setupPageUrl;
}

// アプリからのメッセージを受け取る
export function addListenerFromUG(type: UGEventType, options?: boolean | AddEventListenerOptions): Promise<UGMessage> {
  return new Promise((resolve, reject) => {
    try {
      if (window.chrome?.webview) {
        window.chrome.webview.addEventListener(
          'message',
          ({ data }) => {
            if (data) {
              console.log('[addListenerFromUG] message received:', data);
              if ((data as UGMessage).type === type) {
                resolve(data);
              }
            }
          },
          options
        );
      } else {
        const errorMsg = '[addListenerFromUG] window.chrome.webview is not defined';
        console.error(errorMsg);
        reject(Error(errorMsg));
      }
    } catch (error) {
      console.error('[addListenerFromUG]', error);
      reject(error);
    }
  });
}

// アプリへメッセージを送信する
export function postMessageToUG(message: UGMessage): void {
  try {
    if (window.chrome?.webview) {
      window.chrome.webview.postMessage(message);
    } else {
      console.error('[postMessageToUG] window.chrome.webview is not defined');
    }
  } catch (error) {
    console.error('[postMessageToUG]', error);
  }
}
