const MarkingLabel = [
  { key: 'CAMERA_FAILURE', name: 'カメラ不良', value: 1 },
  { key: 'POOR_VISIBILITY', name: '視界不良', value: 2 },
  { key: 'POOR_VISION', name: '目線不良', value: 3 },
  { key: 'POOR_WORKMANSHIP', name: '顔向き不良', value: 4 },
  { key: 'POOR_LIGHTNESS', name: '明度不良', value: 5 },
  { key: 'NO_FACIAL_RECOGNITION', name: '顔認識不可', value: 6 },
  { key: 'PERSONALITY_MISMATCH', name: '人物不一致', value: 7 },
  { key: 'MULTIPLE_PEOPLE_IDENTIFICATION', name: '複数人物確認', value: 8 },
  { key: 'UNIDENTIFIED_PERSON', name: '人物未確認', value: 9 },
  { key: 'MICROPHONE_FAILURE', name: 'マイク不良', value: 10 },
  { key: 'MICROPHONE_VOLUME_FAILURE', name: 'マイク音量不良', value: 11 },
  { key: 'VOICE_VERIFICATION', name: '音声確認', value: 12 },
  { key: 'UNABLE_TO_VERIFY_AUDIO', name: '音声確認不可', value: 13 },
  { key: 'CELL_PHONE_UNRECOGNIZABLE', name: '携帯認識不可', value: 14 },
  { key: 'REGULATION_INJUSTICE', name: 'レギュレーション不正', value: 15 },
];
export default MarkingLabel;
