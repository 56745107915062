import type { Modals } from '@/plugins/global/modals.ts';

export function alertError({
  error,
  defaultErrorMessage,
  $modals,
}: {
  // TODO: remove `any` after TypeScript 4.0
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-0.html#unknown-on-catch-clause-bindings
  error: unknown | any; // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultErrorMessage: string;
  $modals?: Modals;
}) {
  console.error(error);
  let message = error?.message;
  if (!message || message === 'OK') message = defaultErrorMessage;
  // await $modals.showErrorAlert(message);
  console.error(message); // 影響範囲を広げないために、alertErrorの中でalertを出すのはやめました。
}
