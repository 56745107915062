import { GetterTree, ActionContext } from 'vuex';
import { AuthControlsState } from '@/store/types/authControlsTypes';
import { ACTION_SET_STATUS, GETTER_GET_STATUS, SET_STATUS } from '@/store/types/authControlsTypes'; // Import mutation and action types
import { Commit } from 'vuex'; // Import Commit type from Vuex
import { RootState } from '../rootStore';

// Define state type as AuthControlsState
const state: AuthControlsState = {
  status: 0,
};

// Define mutation to set status
const mutations = {
  [SET_STATUS](state: AuthControlsState, newStatus: number) {
    state.status = newStatus;
  },
};

// Define actions to commit mutation
const actions = {
  [ACTION_SET_STATUS]({ commit }: ActionContext<AuthControlsState, any>, newStatus: number) {
    commit(SET_STATUS, newStatus);
  },
};

// Define getters to access status
const getters: GetterTree<AuthControlsState, RootState> = {
  getStatus: (state) => state.status,
};

// Export the AuthControls module
export default {
  state,
  mutations,
  actions,
  getters,
};
