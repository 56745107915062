export enum TesterState {
  NONE = 0,
  LOGIN = 1,
  MATCHED = 2,
  IDENTIFICATED = 3,
  BEFORE_EXAM = 4,
  EXAMING = 5,
  INTERRUPTION_EXAM = 6,
  FINISH_EXAM = 7,
}
