// Getters
export const GETTER_IS_STARTUPPED: string = 'root/GETTER_IS_STARTUPPED';
export const GETTER_STARTUP: string = 'root/GETTER_STARTUP';
export const GETTER_STARTUP_URL: string = 'root/GETTER_STARTUP_URL';
export const GETTER_DISPLAY_LANG: string = 'root/GETTER_DISPLAY_LANG';

// Mutations
export const MUTATION_STARTUP: string = 'root/MUTATION_STARTUP';
export const MUTATION_LANGUAGE: string = 'root/MUTATION_LANGUAGE';

// Actions
export const ACTION_STARTUP: string = 'root/ACTION_STARTUP';
export const ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE: string = 'root/ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE';
export const ACTION_STARTUP_FOR_F5: string = 'root/ACTION_STARTUP_FOR_F5';
export const ACTION_STARTUP_FOR_CHILD_WINDOW: string = 'root/ACTION_STARTUP_FOR_CHILD_WINDOW';
export const ACTION_SET_RAW_STARTUP_PARAMETER: string = 'root/ACTION_SET_RAW_STARTUP_PARAMETER';
