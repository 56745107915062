/* eslint-disable camelcase */
export class TesterExaminingTermAdapter {
  constructor(public templateBody: string = '') {}
}

export type TesterExaminingTermRequestType = {
  template_id: number;
};

export type TesterExaminingTermResponseType = {
  status: number;
  message: string;
  result: { template_body: string };
};
