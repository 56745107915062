// Getters
export const GETTER_MATCHING: string = 'matching/GETTER_MATCHING';
export const GETTER_IS_ACCESSING: string = 'matching/GETTER_IS_ACCESSING';

// Mutations
export const MUTATION_MATCHING: string = 'matching/MUTATION_MATCHING';
export const MUTATION_DELETE_MATCHING: string = 'matching/MUTATION_DELETE_MATCHING';
export const MUTATION_ADD_PROMISE: string = 'matching/MUTATION_ADD_PROMISE';
export const MUTATION_REMOVE_PROMISE: string = 'matching/MUTATION_REMOVE_PROMISE';

// Actions
export const ACTION_MATCHING: string = 'matching/ACTION_MATCHING';
export const ACTION_DELETE_MATCHING: string = 'matching/ACTION_DELETE_MATCHING';
export const ACTION_MATCHING__: string = 'matching/ACTION_MATCHING__';
export const ACTION_DELETE_MATCHING__: string = 'matching/ACTION_DELETE_MATCHING__';
