export default `
リモートテスティング利用規約

この利用規約（以下「本規約」）は、株式会社イー・コミュニケーションズ（以下「当社」）が管理・運営するリモートテスティング（以下「本サービス」）を利用するにあたって遵守いただくべき事項を定めたものです。本サービスをご利用いただいた場合、本規約に同意いただいたものといたします。
　なお、本規約で使用される用語は、本規約の最下部に記載された「本規約における用語の定義」を参照してください。

第1条　本サービスの内容
本サービスは、試験の受験中に、Webカメラやマイクを通して映像、音声、PC画面の表示内容等を取得し、それを試験監督または監視ソフトウェアが監視し、取得した結果と監視結果を試験実施団体または申込団体に提供することで、利用者本人が適切に試験を受けたことを試験実施団体や申込団体が確認できるようにするサービスです。利用者はWebカメラを通じた映像、音声、PC画面の情報、その他当社が本サービスにより利用者から取得したすべての情報を、試験実施団体ならびに申込団体に提供することに同意するものとします。また、利用者は、当社が利用者から取得した情報をAIの学習データとして使用するなど、本サービスの改善のために利用することに同意するものとします。

第2条　本サービスを利用にあたって必要となる機器等について
(1) 本サービスを利用するために必要なコンピューター、ネットワーク機器、Webカメラ、マイク、その他の必要な機器やインターネットに接続可能なネットワーク回線は、利用者が自らの費用と責任で準備するものとします。また、本サービスへのアクセスにかかる通信費用についても、利用者の負担とします。
(2) 本サービスを利用するために利用者が準備するコンピューター等の機器の種類、OSのバージョン等は、別途本サービスのサイトに記載する推奨環境に適合しているものであるとともに、別途試験実施団体から試験毎の利用可能環境が提示されている場合にはそれに適合したものである必要があります。
(3) 利用者は、本サービスを利用して受験をする前に、予め動作確認用ページにて機器等が適合しているかを確認しなければなりません。
(4) 利用者の機器・設備の不備、インターネット接続の不具合等によって、本サービスの利用に支障が出た場合の責任は、当社は負わないこととします。

第3条　IDならびに認証情報
(1) 本サービスを利用するにあたっては、以下のいずれかの形で利用者を識別・認証します。
a) 当社が運営する試験サービスJ-Testingで受験申し込みを行い、J-Testingから連携する形で受験を行う場合、J-TestingのログインID、パスワードにより認証されていることをもって本サービスの利用者として識別・認証されます。
b) a) 以外の場合、当社が提供するMASTER CBT Plusまたは、本サービスと連携する外部試験システムのIDおよびパスワード（以下「認証情報」）で識別・認証を行います。

(2) 前項記載の認証情報については、前項a)の場合にはJ-Testing利用規約、それ以外の場合には当該試験を運営する試験実施団体の指示に従い、その使用および管理については利用者が一切の責任を負うものとし、第三者に使用させたり、貸与、譲渡等をしてはならないものとします。

(3) 認証情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任は利用者が負うものとし、当社は一切の責任を負いません。


第4条　個人情報の取扱い
個人情報の取り扱いについては、別途掲載する「リモートテスティングにおける個人情報の取り扱いについて」の通りとし、利用者は同意の上、本サービスを利用するものとします。

第5条　受験環境の整備
利用者は、以下の条件に合う受験環境を用意しなければなりません。受験環境が用意できないことにより指定された期日に受験ができない場合に、試験日時の変更、再試験を行えるかどうかは、試験実施団体の判断によります。また試験日時の変更、再試験に伴い、費用が発生する場合には、原則として利用者の負担となります。
(1) 	受験する場所には、他の人が入ってこないようにしてください。
(2) 	携帯電話は、電源をオフにして、ご自身の視界に入らないところに置いてください。
(3) 	受験中は、試験実施団体より使用が許可されている場合のみ、電卓、テキスト、メモ用紙等の使用が可能です。
(4) 	受験する机の上には、書類、本などは置かず、視界に入らないところに置いてください。
(5) 	文字が書かれたポスター等の掲示物、書籍（タイトルのみも含む）は、外して視界に入らないようにするか、カバーをかけてください。
(6) 	テレビ、ラジオ、音楽などの音は消してください。
(7) 	受験に使用するモニター以外のモニター、テレビなど映像を映すことができる機器は、映像が視界に入らないように向きを変えるかカバーをかけてください。
(8) 	試験監督からの指示があった場合、Webカメラを回して、受験する部屋と机の360度録画を作成します。ノートPCの内蔵Webカメラの場合など、本体に固定されている場合には、本体ごと回せるように、電源コード等の状態を確認してください。
(9) 	試験監督からの指示があった場合、Web カメラ を使って あなたの顔と本人確認書を撮影して保存します。本人確認書に記載された、本人確認に必要な情報（顔写真、氏名、生年月日）以外のプライバシー情報（マイナンバー、本籍地、マイナンバーカードの臓器提供意思表示欄など）を含む部分は、マスキングテープ、付箋等であらかじめマスクしておいてください。
(10) 	サングラス、マスク等は、本人確認や受験状況の確認に支障が出ますので、本人確認の際、ならびに、受験中は外してください。
(11) 	受験画面を撮影または録画する機器（本サービスで使用するもの以外の監視カメラ、防犯カメラ等も含む）が無いことを確認してください。ある場合には、受験画面が映らないようにカバーをしてください。
(12) 	別途試験実施団体からの許可が無い限り、受験中に飲食物を摂取することはできません。健康上の理由により、飲食物、薬品等を受験中にとる必要がある場合は、あらかじめ試験実施団体に連絡して許可を得てください。

第6条　試験監督からの指示について
受験システム、または、試験監督からの指示は、画面上に表示されますので、試験監督から画面上に指示があった場合は、その指示に従ってください。

第7条　禁止事項
サービス利用中は以下の行為を禁止します。ただし、病気、障がい等のため、事前に試験実施団体の許可を得ている場合を除きます。
(1) 	利用者以外の者の補助を受ける行為
(2) 	試験中に利用者が入れ替わること
(3) 	試験中に離籍すること
(4) 	記述問題の無い試験で試験中にマウス以外のキーボードなどに触れる行為
(5) 	試験システム以外のアプリケーションを開いたり、試験システム以外のページを閲覧する行為
(6) 	試験中に他人と会話する行為
(7) 	試験中に継続的にまたは頻繁に試験画面以外を見る行為
(8) 	試験中に他の人が利用者に近寄ること
(9) 	携帯電話及びその他の電子機器を使用する行為
(10) 	試験中に電卓、テキスト、その他許可されていない物を使用する行為
(11) 	試験中に、試験画面を録画、撮影すること、ならびに画面キャプチャの取得、問題内容をメモ用紙に記録するなど、問題漏洩につながる行為
(12) 	第5条所定の要件を満たさない環境で受験する行為
(13) 	その他、不正が疑われる行為


第8条　サービス利用の停止処分
当社は、利用者が次のいずれかに該当する場合、試験の実施を中止し、サービスの利用を停止した上で、試験実施団体に報告いたします。

(1) 利用者が、本規約に違反したとき
(2) 利用者が、試験監督の指示に従わない時
(3) 利用者が、本サービスの利用を継続することにより、当社が、自らの業務の遂行上または技術上の支障が生じると判断する合理的事由が生じたとき
(4) 当社の権利・財産を侵害する行為、および侵害するおそれのある行為があったとき
(5) 利用者が、暴力団、暴力団員、暴力団準構成員、総会屋その他の反社会的勢力に関与するものであると判明したとき。
(6) その他、当社が合理的事由により利用者として承認することが不適当と判断したとき

第9条　本サービスの変更・中止・中断・終了
(1) 当社は、本サービスの運営に関し、必要と認める場合、当社は、利用者の本サービスへの利用を制限することができるものとします。
(2) 当社は、以下の事項に該当する場合、本サービスの運営を変更・中止・中断・終了できるものとします。
① 本サービスのシステムの保守を定期的にまたは緊急に行なう場合
② 通信回線の故障、または火災、停電、天災地変、戦争などの不可抗力により、本サービスの提供が困難になった場合
③ その他本サービスを提供できない合理的事由が生じた場合
(3) 当社は、前項の規定により、本サービスの運営を変更・中止・中断・終了するときは、あらかじめその旨利用者に通知するものとします。ただし、緊急の場合は、この限りではありません
(4) 当社は、利用者に対する一定の予告期間をもって本サービスの一部もしくは全部を終了することができるものとします。

第10条　当社の責任
(1)当社の責任は、利用者が支障なく本サービスを利用できるよう、善良な管理者の注意義務をもって本サービスを運営することに限られるものとします。
(2) 当社は、第9条(2)項の規定により、本サービスの運営を中止・中断・終了するときは、代替手段等について試験実施団体と協議の上対応いたします。利用者からの個別の要望には当社では対応する責任を負いません。
(3)　その他本規約に定める条項によって、当社の責任は限定されるものとします。


第11条　全般
(1)本サービスに関連して、利用者と当社との間で紛争が生じた場合には、当事者間において誠意をもって解決するものとします。
(2)本サービスの利用に関しては、日本法に拘束されることに同意するものとし、また、本サービスの利用について訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
(3)当社は、本規約を、予告なく変更することできるものします。必ず最新情報をご確認ください。

＜本規約における用語の定義＞

(1) 本サービス
株式会社イー・コミュニケーションズが運営する「リモートテスティング」のサービス。

(2) 利用者
本規約に同意した上でリモートテスティングを利用する人を「利用者」と呼びます。リモートテスティングの一部のサービスは、利用者認証をせずに利用できますが、それらの利用にあたっても本規約が適用されるものとします。

(3) 試験実施団体
当社と本サービスの利用契約を行い、試験を主催する団体。

(4) 申込団体
試験実施団体の主催する試験を団体で申し込む団体。


制定日：2020年10月1日
株式会社イー・コミュニケーションズ




リモートテスティングにおける個人情報の取り扱いについて

株式会社イー・コミュニケーションズ（以下「当社」）は、当社が管理・運営するリモートテスティング（以下「本サービス」）において、個人情報を以下の通り取り扱います。

１．個人情報の利用目的
    本サイトに登録いただきました個人情報は以下の目的で利用します。
・	サポート窓口へのお問い合わせにあたって、利用者の氏名、連絡先等を取得します。この場合に取得した情報は、お問い合わせに対応するために必要な範囲に限定して利用します。
・	室内チェックを行う試験の場合、室内の状況をWebカメラを通した映像ならびにPCのマイクを通した音声で監視者が確認するとともに保存されます。この映像ならびに音声は当社、試験実施団体および申込団体が受験環境が適切であるかを確認するために利用します。
・	本人確認プロセスでは、Webカメラを通して得られる映像で利用者、ならびに、利用者の身分証明書を撮影し、保存します。この映像は当社、試験実施団体および申込団体が受験者本人であることを確認するために利用します。
・	受験においては、Webカメラを通して得られる映像ならびにPCのマイクを通した音声を取得し、保存します。この映像ならびに音声は、受験中に不正な行為が行われていないかを当社の監視者が確認するために使用されるとともに、保存され、後日当社、試験実施団体および申込団体が不正の有無を確認するために使用します。
・	上記室内チェックから受験における全プロセスを通じて、受験者と監視者がチャットによりやりとりした内容は、受験の記録として保存され、後日当社、試験実施団体および申込団体が不正の有無を確認するために使用します。
・	上記室内チェックから受験終了までの過程において取得された映像および音声（以下「映像等」）はAIを使用した不正の有無を判定するために使用される場合があります。
・	映像等はAIによる本人確認、ならびに監視の品質を向上するための学習データとして使用される場合があります。
・	利用者がアクセスした際に取得されるクッキー（cookie）、アクセスログ（IPアドレス、リファラー情報、ユーザーエージェント、アクセス日時等を含む）の情報を、お問い合わせへの対応、利用状況の調査、サービス改善のために使用します。

２．情報提供の任意性
　個人情報を提供するかどうかにつきましては、利用者自身で判断してください。必要な個人情報のご提供のない場合は、本サービスの利用ができない場合や、受験にあたって不正の有無の判断が適切にできないため、正当な受験であるとみなされない場合がありますので、ご了承ください。

３．個人情報の第三者提供について
　本サービスを利用して試験、学習サービス等のお申し込みをした場合には、以下の範囲で個人情報を第三者に提供します。
・	サポート窓口へのお問い合わせの際に取得した個人情報は、お問い合わせへの対応のために、試験実施団体および申込団体に提供される場合があります。提供される個人情報は、お問い合わせにあたって取得した利用者の氏名、連絡先、お問い合わせ内容と、お問い合わせのあった利用者がサービス利用にあたって保存された映像・音声情報を含みます。
・	本サービスの利用にあたって取得した映像等は、当社が行った室内チェック、本人確認の結果とともに試験実施団体および申込団体に提供され、試験実施団体にて不正の有無の確認を行うために利用されます。

　上記以外の場合においては、人の生命、身体または財産の保護のために緊急に必要がある場合、その他個人情報保護法第23条第1項各号所定の場合を除いては第三者に提供・開示しません。

４．個人情報の委託について
　当社は「１．個人情報の利用目的」に記載した利用目的達成のために必要な範囲内で、委託先と個人情報の適切な保護に関する契約を締結した上で、第三者へ個人情報の処理の業務を委託する場合があります。

５．個人情報に関するお問い合わせ、開示、訂正、削除の依頼について
  当社に提供いただいた個人情報の開示、訂正または削除などを希望される場合は、リモートテスティングサポート窓口にご連絡ください。開示、訂正または削除を受け付けるにあたり、利用者ご本人である証明書を確認させていただく場合及び当社が定める様式による申請手続きを必要とする場合があります。

６．本人が容易に認識できない方法による個人情報の取得
利用者がアクセスした際に取得されるクッキー（cookie）、アクセスログ（IPアドレス、リファラー情報、ユーザーエージェント、アクセス日時等を含む）の情報を、お問い合わせへの対応、利用状況の調査、サービス改善のために使用します。


株式会社イー・コミュニケーションズ
個人情報保護管理者 開発本部長CTO
 03-3560-3901 

個人情報のお問合せ先
〒106-0032
東京都港区六本木2-4-5 六本木Dスクエア9F
株式会社イー・コミュニケーションズ
個人情報相談窓口
privacy_mgr@e-coms.co.jp

`;
