// Getters
export const GETTER_SELECT_SHORTEN_URL_LIST: string = 'createShortUrlPageType/GETTER_SELECT_SHORTEN_URL_LIST';

// Mutations
export const MUTATION_SELECT_SHORTEN_URL: string = 'createShortUrlPageType/MUTATION_SELECT_SHORTEN_URL';

// Actions
export const ACTION_SELECT_SHORTEN_URL_GET: string = 'createShortUrlPageType/ACTION_SELECT_SHORTEN_URL_GET';
export const ACTION_UPDATE_SHORTEN_URL_POST: string = 'createShortUrlPageType/ACTION_UPDATE_SHORTEN_URL_POST';
export const ACTION_INSERT_SHORTEN_URL_POST: string = 'createShortUrlPageType/ACTION_INSERT_SHORTEN_URL_POST';
export const ACTION_DELETE_SHORTEN_URL_POST: string = 'createShortUrlPageType/ACTION_DELETE_SHORTEN_URL_POST';
