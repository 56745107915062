// Getters

// AI解析依頼初期パラメータ
export const GETTER_ANALYSIS_REQUEST_PAGE_GET_INITIAL_PARAM: string =
  'analysisRequestPage/GETTER_ANALYSIS_REQUEST_PAGE_GET_INITIAL_PARAM';
// AI解析依頼フォーム
export const GETTER_ANALYSIS_REQUEST_PAGE_GET_FORM_VALUES: string =
  'analysisRequestPage/GETTER_ANALYSIS_REQUEST_PAGE_GET_FORM_VALUES';
// AI解析依頼テーブル
export const GETTER_ANALYSIS_REQUEST_PAGE_GET_TABLE_VALUES: string =
  'analysisRequestPage/GETTER_ANALYSIS_REQUEST_PAGE_GET_TABLE_VALUES';

// Mutations
// AI解析依頼初期パラメータ
export const MUTATION_ANALYSIS_REQUEST_PAGE_SET_INITIAL_PARAM: string =
  'analysisRequestPage/MUTATION_ANALYSIS_REQUEST_PAGE_SET_INITIAL_PARAM';
// AI解析依頼フォーム
export const MUTATION_ANALYSIS_REQUEST_PAGE_SET_FORM_VALUES: string =
  'analysisRequestPage/MUTATION_ANALYSIS_REQUEST_PAGE_SET_FORM_VALUES';
export const MUTATION_ANALYSIS_REQUEST_PAGE_CLEAR_FORM_VALUES: string =
  'analysisRequestPage/MUTATION_ANALYSIS_REQUEST_PAGE_CLEAR_FORM_VALUES';
// AI解析依頼テーブル
export const MUTATION_ANALYSIS_REQUEST_PAGE_SET_TABLE_VALUES: string =
  'analysisRequestPage/MUTATION_ANALYSIS_REQUEST_PAGE_SET_TABLE_VALUES';

// Actions
// AI解析依頼初期パラメータ
export const ACTION_ANALYSIS_REQUEST_PAGE_CALL_API_ANALYSIS_REQUEST_INITIAL_PARAM: string =
  'analysisRequestPage/ACTION_ANALYSIS_REQUEST_PAGE_CALL_API_ANALYSIS_REQUEST_INITIAL_PARAM';
// AI解析依頼フォーム
export const ACTION_ANALYSIS_REQUEST_PAGE_SET_FORM_VALUES: string =
  'analysisRequestPage/ACTION_ANALYSIS_REQUEST_PAGE_SET_FORM_VALUES';
export const ACTION_ANALYSIS_REQUEST_PAGE_CLEAR_FORM_VALUES: string =
  'analysisRequestPage/ACTION_ANALYSIS_REQUEST_PAGE_CLEAR_FORM_VALUES';
export const ACTION_ANALYSIS_REQUEST_PAGE_CALL_API_ANALYSIS_REQUEST_REQUEST: string =
  'analysisRequestPage/ACTION_ANALYSIS_REQUEST_PAGE_CALL_API_ANALYSIS_REQUEST_REQUEST';
// AI解析依頼テーブル
export const ACTION_ANALYSIS_REQUEST_PAGE_SET_TABLE_VALUES: string =
  'analysisRequestPage/ACTION_ANALYSIS_REQUEST_PAGE_SET_TABLE_VALUES';
export const ACTION_ANALYSIS_REQUEST_PAGE_CALL_API_ANALYSIS_REQUEST_INDEX: string =
  'analysisRequestPage/ACTION_ANALYSIS_REQUEST_PAGE_CALL_API_ANALYSIS_REQUEST_INDEX';
