// Getters
export const GETTER_PROCTOR_ADMIN_PERMISSION_IP_PAGE_GET_IS_VALID_IP: string =
  'proctorAdminPermissionIpPage/GETTER_PROCTOR_ADMIN_PERMISSION_IP_PAGE_GET_IS_VALID_IP';

// Mutations
export const MUTATION_PROCTOR_ADMIN_PERMISSION_IP_PAGE_SET_VALUES: string =
  'proctorAdminPermissionIpPage/MUTATION_PROCTOR_ADMIN_PERMISSION_IP_PAGE_SET_VALUES';

// Actions
export const ACTION_PROCTOR_ADMIN_PERMISSION_IP_PAGE_SET_VALUES: string =
  'proctorAdminPermissionIpPage/ACTION_PROCTOR_ADMIN_PERMISSION_IP_PAGE_SET_VALUES';
export const ACTION_PROCTOR_ADMIN_PERMISSION_IP_PAGE_CALL_API_SELECT: string =
  'proctorAdminPermissionIpPage/ACTION_PROCTOR_ADMIN_PERMISSION_IP_PAGE_CALL_API_SELECT';
