// Getters
export const GETTER_CHECKERS_MONITORING_PAGE: string = 'checkersMonitoringPage/GETTER_CHECKERS_MONITORING_PAGE';

// Mutations
export const MUTATION_CHECKERS_MONITORING_PAGE_SET: string =
  'checkersMonitoringPage/MUTATION_CHECKERS_MONITORING_PAGE_SET';
export const MUTATION_CHECKERS_MONITORING_PAGE_SET_MATCHINGS: string =
  'checkersMonitoringPage/MUTATION_CHECKERS_MONITORING_PAGE_SET_MATCHINGS';
export const MUTATION_CHECKERS_MONITORING_PAGE_SET_NOT_MATCHINGS: string =
  'checkersMonitoringPage/MUTATION_CHECKERS_MONITORING_PAGE_SET_NOT_MATCHINGS';
export const MUTATION_CHECKERS_MONITORING_PAGE_SET_KICKED_USERS: string =
  'checkersMonitoringPage/MUTATION_CHECKERS_MONITORING_PAGE_SET_KICKED_USERS';

// Actions
export const ACTION_CHECKERS_MONITORING_PAGE_GET_MONITORINGS: string =
  'checkersMonitoringPage/ACTION_CHECKERS_MONITORING_PAGE_GET_MONITORINGS';
export const ACTION_CHECKERS_MONITORING_PAGE_KICK_OUT: string =
  'checkersMonitoringPage/ACTION_CHECKERS_MONITORING_PAGE_KICK_OUT';
export const ACTION_CHECKERS_MONITORING_PAGE_DELETE_KICK_OUT: string =
  'checkersMonitoringPage/ACTION_CHECKERS_MONITORING_PAGE_DELETE_KICK_OUT';
export const ACTION_CHECKERS_MONITORING_PAGE_DELETE_KICK_OUT_AND_CHANGE_STATUS: string =
  'checkersMonitoringPage/ACTION_CHECKERS_MONITORING_PAGE_DELETE_KICK_OUT_AND_CHANGE_STATUS';
export const ACTION_CHECKERS_MONITORING_PAGE_DELETE_KICK_OUT_AND_FILTER_KICKED_USERS: string =
  'checkersMonitoringPage/ACTION_CHECKERS_MONITORING_PAGE_DELETE_KICK_OUT_AND_FILTER_KICKED_USERS';
