import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6fc3a51e = () => interopDefault(import('../pages/access-limit/index.vue' /* webpackChunkName: "pages/access-limit/index" */))
const _30af5efd = () => interopDefault(import('../pages/analysis-request/index.vue' /* webpackChunkName: "pages/analysis-request/index" */))
const _2da1c8cf = () => interopDefault(import('../pages/close/index.vue' /* webpackChunkName: "pages/close/index" */))
const _bd11740a = () => interopDefault(import('../pages/create-shorturl/index.vue' /* webpackChunkName: "pages/create-shorturl/index" */))
const _1d3850e6 = () => interopDefault(import('../pages/create-shorturl-List/index.vue' /* webpackChunkName: "pages/create-shorturl-List/index" */))
const _7ebeec22 = () => interopDefault(import('../pages/delete-plan/index.vue' /* webpackChunkName: "pages/delete-plan/index" */))
const _21eebb8d = () => interopDefault(import('../pages/delete-setting/index.vue' /* webpackChunkName: "pages/delete-setting/index" */))
const _61a9f547 = () => interopDefault(import('../pages/env-setting/index.vue' /* webpackChunkName: "pages/env-setting/index" */))
const _155e1dcc = () => interopDefault(import('../pages/exam-end/index.vue' /* webpackChunkName: "pages/exam-end/index" */))
const _47e63be6 = () => interopDefault(import('../pages/examinees/index.vue' /* webpackChunkName: "pages/examinees/index" */))
const _4c78c535 = () => interopDefault(import('../pages/examining/index.vue' /* webpackChunkName: "pages/examining/index" */))
const _078591cd = () => interopDefault(import('../pages/identification/index.vue' /* webpackChunkName: "pages/identification/index" */))
const _084a05fe = () => interopDefault(import('../pages/identification-ai/index.vue' /* webpackChunkName: "pages/identification-ai/index" */))
const _8b04eafa = () => interopDefault(import('../pages/iframe/index.vue' /* webpackChunkName: "pages/iframe/index" */))
const _20c1bede = () => interopDefault(import('../pages/inquiry/index.vue' /* webpackChunkName: "pages/inquiry/index" */))
const _76f38100 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _73add689 = () => interopDefault(import('../pages/logins/index.vue' /* webpackChunkName: "pages/logins/index" */))
const _7463e300 = () => interopDefault(import('../pages/logview/index.vue' /* webpackChunkName: "pages/logview/index" */))
const _48c4c362 = () => interopDefault(import('../pages/management/index.vue' /* webpackChunkName: "pages/management/index" */))
const _cfb925b2 = () => interopDefault(import('../pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _072fdb5f = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _2be378bb = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _8bf764c4 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _0108f327 = () => interopDefault(import('../pages/alerting/system-error.vue' /* webpackChunkName: "pages/alerting/system-error" */))
const _0e7e0430 = () => interopDefault(import('../pages/checkers/monitoring/index.vue' /* webpackChunkName: "pages/checkers/monitoring/index" */))
const _134bed0d = () => interopDefault(import('../pages/alerting/_id.vue' /* webpackChunkName: "pages/alerting/_id" */))
const _fef6e9ba = () => interopDefault(import('../pages/create-shorturl/_id.vue' /* webpackChunkName: "pages/create-shorturl/_id" */))
const _b05a185e = () => interopDefault(import('../pages/examinees/_id/index.vue' /* webpackChunkName: "pages/examinees/_id/index" */))
const _717a96e8 = () => interopDefault(import('../pages/examinees/_id/login.vue' /* webpackChunkName: "pages/examinees/_id/login" */))
const _56f83926 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-limit",
    component: _6fc3a51e,
    name: "access-limit"
  }, {
    path: "/analysis-request",
    component: _30af5efd,
    name: "analysis-request"
  }, {
    path: "/close",
    component: _2da1c8cf,
    name: "close"
  }, {
    path: "/create-shorturl",
    component: _bd11740a,
    name: "create-shorturl"
  }, {
    path: "/create-shorturl-List",
    component: _1d3850e6,
    name: "create-shorturl-List"
  }, {
    path: "/delete-plan",
    component: _7ebeec22,
    name: "delete-plan"
  }, {
    path: "/delete-setting",
    component: _21eebb8d,
    name: "delete-setting"
  }, {
    path: "/env-setting",
    component: _61a9f547,
    name: "env-setting"
  }, {
    path: "/exam-end",
    component: _155e1dcc,
    name: "exam-end"
  }, {
    path: "/examinees",
    component: _47e63be6,
    name: "examinees"
  }, {
    path: "/examining",
    component: _4c78c535,
    name: "examining"
  }, {
    path: "/identification",
    component: _078591cd,
    name: "identification"
  }, {
    path: "/identification-ai",
    component: _084a05fe,
    name: "identification-ai"
  }, {
    path: "/iframe",
    component: _8b04eafa,
    name: "iframe"
  }, {
    path: "/inquiry",
    component: _20c1bede,
    name: "inquiry"
  }, {
    path: "/login",
    component: _76f38100,
    name: "login"
  }, {
    path: "/logins",
    component: _73add689,
    name: "logins"
  }, {
    path: "/logview",
    component: _7463e300,
    name: "logview"
  }, {
    path: "/management",
    component: _48c4c362,
    name: "management"
  }, {
    path: "/monitoring",
    component: _cfb925b2,
    name: "monitoring"
  }, {
    path: "/privacy",
    component: _072fdb5f,
    name: "privacy"
  }, {
    path: "/redirect",
    component: _2be378bb,
    name: "redirect"
  }, {
    path: "/terms",
    component: _8bf764c4,
    name: "terms"
  }, {
    path: "/alerting/system-error",
    component: _0108f327,
    name: "alerting-system-error"
  }, {
    path: "/checkers/monitoring",
    component: _0e7e0430,
    name: "checkers-monitoring"
  }, {
    path: "/alerting/:id?",
    component: _134bed0d,
    name: "alerting-id"
  }, {
    path: "/create-shorturl/:id?",
    component: _fef6e9ba,
    name: "create-shorturl-id"
  }, {
    path: "/examinees/:id",
    component: _b05a185e,
    name: "examinees-id"
  }, {
    path: "/examinees/:id/login",
    component: _717a96e8,
    name: "examinees-id-login"
  }, {
    path: "/",
    component: _56f83926,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
