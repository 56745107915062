// Getters
export const GETTER_IS_LOGGED_IN: string = 'login/GETTER_IS_LOGGED_IN';
export const GETTER_LOGIN: string = 'login/GETTER_LOGIN';
export const GETTER_HAS_ACCESS_TOKEN: string = 'login/GETTER_HAS_ACCESS_TOKEN';
export const GETTER_GET_ACCESS_TOKEN: string = 'login/GETTER_GET_ACCESS_TOKEN';

// Mutations
export const MUTATION_SET_ACCESS_TOKEN: string = 'login/MUTATION_SET_ACCESS_TOKEN';
export const MUTATION_SET_ACCESS_TOKEN_WITH_ACTOR: string = 'login/MUTATION_SET_ACCESS_TOKEN_WITH_ACTOR';
export const MUTATION_LOGIN: string = 'login/MUTATION_LOGIN';
export const MUTATION_LOGOUT: string = 'login/MUTATION_LOGOUT';

// Actions
export const ACTION_SET_ACCESS_TOKEN = 'login/ACTION_SET_ACCESS_TOKEN';
export const ACTION_SET_ACCESS_TOKEN_WITH_ACTOR = 'login/ACTION_SET_ACCESS_TOKEN_WITH_ACTOR';
export const ACTION_LOGIN: string = 'login/ACTION_LOGIN';
export const ACTION_LOGIN_FOR_JTESTING: string = 'login/ACTION_LOGIN_FOR_JTESTING';
export const ACTION_LOGIN_FOR_CHILD_WINDOW = 'login/ACTION_LOGIN_FOR_CHILD_WINDOW';
export const ACTION_LOGOUT: string = 'login/ACTION_LOGOUT';
