// プライバシーポリシー (footer) 関連のAPI
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { alertError } from './error-handling';
import type { TemplateListFromAPI, TemplateItem } from './terms';
import type { Modals } from '@/plugins/global/modals.ts';
import { Endpoint } from '@/store/const/endpoint';

export interface PrivacyPolicy extends TemplateItem {}
export interface PrivacyPolicyList extends TemplateListFromAPI {}

const defaultPrivacyPolicy: PrivacyPolicy = {
  footerURL: 'https://www.e-coms.co.jp/privacy',
  templateBody: '',
};
const { PRIVACY_POLICY, PRIVACY_POLICY_LIST } = Endpoint;

export async function getPrivacyPolicy({
  $axios,
  $modals,
  id,
}: {
  $axios: NuxtAxiosInstance;
  $modals: Modals;
  id: string | number;
}): Promise<PrivacyPolicy> {
  const defaultErrorMessage = 'プライバシーポリシーの取得に失敗しました';
  try {
    const data = await $axios.$get(PRIVACY_POLICY.url, {
      params: { template_id: id },
    });
    const footerURL = data?.result?.template_footer_url;
    const templateBody = data?.result?.template_body;
    if (typeof footerURL === 'string' && typeof templateBody === 'string') {
      return { footerURL, templateBody };
    }
    throw new Error(defaultErrorMessage);
  } catch (error) {
    alertError({ error, defaultErrorMessage, $modals });
    return defaultPrivacyPolicy;
  }
}

export async function getPrivacyPolicyList({
  $axios,
  $modals,
}: {
  $axios: NuxtAxiosInstance;
  $modals: Modals;
}): Promise<PrivacyPolicyList> {
  const defaultErrorMessage = 'プライバシーポリシーリストの取得に失敗しました';
  try {
    const data = await $axios.$get(PRIVACY_POLICY_LIST.url);
    if (!Array.isArray(data)) throw new Error(defaultErrorMessage);
    return data;
  } catch (error) {
    alertError({ error, defaultErrorMessage, $modals });
    return [];
  }
}
