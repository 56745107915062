/* eslint-disable camelcase */
export class AccessLimitInsertAdapter {
  constructor() {}
}

export type AccessLimitInsertRequestType = {};

export type AccessLimitInsertResponseType = {
  status: number;
  message: string;
  result: null;
};
