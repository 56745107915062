/* eslint-disable camelcase */
export class TesterRecordingAdapter {
  public accessKeyId: string = '';
  public secretAccessKey: string = '';
  public sessionToken: string = '';
  public bucket: string = '';
  public key: string = '';
  public uploadId: string = '';
  public interval: number = 0;
}

type Result = {
  access_key_id: string;
  secret_access_key: string;
  session_token: string;
  bucket: string;
  key: string;
  upload_id: string;
  interval: number;
};

export type TesterRecordingRequestType = {
  method: string;
};

export type TesterRecordingResponseType = {
  status: number;
  result: Result;
  message: string;
};

export class TesterRecordingRecoveryAdapter {
  public static fromResponse(_response: TesterRecordingRecoveryResponseType): TesterRecordingRecoveryAdapter {
    const r = new TesterRecordingRecoveryAdapter();
    return r;
  }
}

export type TesterRecordingRecoveryRequestType = {
  key: string;
};

export type TesterRecordingRecoveryResponseType = {
  status: number;
  message: string;
  result?: any;
};
