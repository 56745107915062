// Getters
// 共通
export const GETTER_TESTER_PAGE: string = 'testerPage/GETTER_TESTER_PAGE';
export const GETTER_TESTER_PAGE_IS_DISCONNECT: string = 'testerPage/GETTER_TESTER_PAGE_IS_DISCONNECT';
export const GETTER_TESTER_PAGE_IS_AUTH_ERROR: string = 'testerPage/GETTER_TESTER_PAGE_IS_AUTH_ERROR';
// 「環境設定」画面
// 「本人認証」画面
export const GETTER_TESTER_PAGE_IS_IDENTIFICATION_AUTHENTICATING: string =
  'testerPage/GETTER_TESTER_PAGE_IS_IDENTIFICATION_AUTHENTICATING';
export const GETTER_TESTER_PAGE_IS_IDENTIFICATION_ACCEPTED: string =
  'testerPage/GETTER_TESTER_PAGE_IS_IDENTIFICATION_ACCEPTED';
export const GETTER_TESTER_PAGE_IS_IDENTIFICATION_REJECTED: string =
  'testerPage/GETTER_TESTER_PAGE_IS_IDENTIFICATION_REJECTED';
export const GETTER_TESTER_PAGE_GET_CHAT_ITEMS: string = 'testerPage/GETTER_TESTER_PAGE_GET_CHAT_ITEMS';
// 「試験」&「試験終了」画面
export const GETTER_TESTER_PAGE_IS_LOGGED_IN_TEST_SYSTEM: string =
  'testerPage/GETTER_TESTER_PAGE_IS_LOGGED_IN_TEST_SYSTEM';
// AI本人認証

// Mutations
// 共通
export const MUTATION_TESTER_PAGE_STEPBAR_STATE: string = 'testerPage/MUTATION_TESTER_PAGE_STEPBAR_STATE';
export const MUTATION_TESTER_PAGE_SET_DISCONNECT: string = 'testerPage/MUTATION_TESTER_PAGE_SET_DISCONNECT';
export const MUTATION_TESTER_PAGE_SET_AUTH_ERROR: string = 'testerPage/MUTATION_TESTER_PAGE_SET_AUTH_ERROR';
// 「環境設定」画面
export const MUTATION_TESTER_PAGE_EXAM_TERM_AGREE: string = 'testerPage/MUTATION_TESTER_PAGE_EXAM_TERM_AGREE';
export const MUTATION_TESTER_PAGE_PROCTER_TERM_READ: string = 'testerPage/MUTATION_TESTER_PAGE_PROCTER_TERM_READ';
export const MUTATION_TESTER_PAGE_PROCTER_TERM_AGREE: string = 'testerPage/MUTATION_TESTER_PAGE_PROCTER_TERM_AGREE';
export const MUTATION_TESTER_PAGE_ENABLE_CAMERA: string = 'testerPage/MUTATION_TESTER_PAGE_ENABLE_CAMERA';
export const MUTATION_TESTER_PAGE_ENABLE_MICROPHONE: string = 'testerPage/MUTATION_TESTER_PAGE_ENABLE_MICROPHONE';
// 「本人認証」画面
export const MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATING: string =
  'testerPage/MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATING';
export const MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED: string =
  'testerPage/MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED';
export const MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED: string =
  'testerPage/MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED';
export const MUTATION_TESTER_PAGE_ADD_CHAT_ITEM: string = 'testerPage/MUTATION_TESTER_PAGE_ADD_CHAT_ITEM';
// 「試験」&「試験終了」画面
export const MUTATION_TESTER_PAGE_SET_TESTER_STATE: string = 'testerPage/MUTATION_TESTER_PAGE_TESTER_STATE';
export const MUTATION_TESTER_PAGE_SET_TESTER: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TESTER';
export const MUTATION_TESTER_PAGE_SET_LOGGED_IN_TEST_SYSTEM: string =
  'testerPage/MUTATION_TESTER_PAGE_SET_LOGGED_IN_TEST_SYSTEM';
export const MUTATION_TESTER_PAGE_SET_TESTER_RECORDING: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_RECORDING';
export const MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_START: string =
  'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_START';
export const MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_END: string =
  'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_END';
export const MUTATION_TESTER_PAGE_TESTER_RECORDING: string = 'testerPage/MUTATION_TESTER_PAGE_TESTER_RECORDING';
export const MUTATION_TESTER_PAGE_SET_TEST_MARKINGS: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TEST_MARKINGS';
// AI本人認証
export const MUTATION_TESTER_PAGE_IDENTITYIMAGE: string = 'testerPage/MUTATION_TESTER_PAGE_IDENTITYIMAGE';
export const MUTATION_TESTER_PAGE_ID_IMAGE: string = 'testerPage/MUTATION_TESTER_PAGE_ID_IMAGE';
export const MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY: string = 'testerPage/MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY';
export const MUTATION_TESTER_PAGE_NOTICE_AI_DISPLAY: string = 'testerPage/MUTATION_TESTER_PAGE_NOTICE_AI_DISPLAY';
export const MUTATION_TESTER_PAGE_COUNT_RETRY_NUM: string = 'testerPage/MUTATION_TESTER_PAGE_COUNT_RETRY_NUM';

// Actions
// 共通
export const ACTION_TESTER_PAGE_STEPBAR_STATE: string = 'testerPage/ACTION_TESTER_PAGE_STEPBAR_STATE';
export const ACTION_TESTER_PAGE_SET_DISCONNECT: string = 'testerPage/ACTION_TESTER_PAGE_SET_DISCONNECT';
export const ACTION_TESTER_PAGE_POST_WEBRTC_MESSAGE: string = 'testerPage/ACTION_TESTER_PAGE_POST_WEBRTC_MESSAGE';
export const ACTION_TESTER_PAGE_LOGOUT: string = 'testerPage/ACTION_TESTER_PAGE_LOGOUT';
// 「環境設定」画面
export const ACTION_TESTER_PAGE_EXAM_TERM_AGREE: string = 'testerPage/ACTION_TESTER_PAGE_EXAM_TERM_AGREE';
export const ACTION_TESTER_PAGE_PROCTER_TERM_READ: string = 'testerPage/ACTION_TESTER_PAGE_PROCTER_TERM_READ';
export const ACTION_TESTER_PAGE_PROCTER_TERM_AGREE: string = 'testerPage/ACTION_TESTER_PAGE_PROCTER_TERM_AGREE';
export const ACTION_TESTER_PAGE_ENABLE_CAMERA: string = 'testerPage/ACTION_TESTER_PAGE_ENABLE_CAMERA';
export const ACTION_TESTER_PAGE_ENABLE_MICROPHONE: string = 'testerPage/ACTION_TESTER_PAGE_ENABLE_MICROPHONE';
// 「本人認証」画面
export const ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATING: string =
  'testerPage/ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATING';
export const ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED: string =
  'testerPage/ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED';
export const ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED: string =
  'testerPage/ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED';
export const GETTER_TESTER_PAGE_ADD_CHAT_ITEM: string = 'testerPage/GETTER_TESTER_PAGE_ADD_CHAT_ITEM';
//  「注意事項」画面
export const ACTION_TESTER_PAGE_GET_EXAMINING_TERM: string = 'testerPage/ACTION_TESTER_PAGE_GET_EXAMINING_TERM';
// 「試験」&「試験終了」画面
export const ACTION_TESTER_PAGE_SET_TESTER_STATE: string = 'testerPage/ACTION_TESTER_PAGE_SET_TESTER_STATE';
export const ACTION_TESTER_PAGE_GET_TESTER: string = 'testerPage/ACTION_TESTER_PAGE_GET_TESTER';
export const ACTION_TESTER_PAGE_GET_ACCESS_TOKEN_FOR_MC: string = 'testerPage/ACTION_TESTER_PAGE_LOGIN_MC_TEST_SYSTEM';
export const ACTION_TESTER_PAGE_GET_ACCESS_TOKEN_FOR_OTHER: string =
  'testerPage/ACTION_TESTER_PAGE_GET_ACCESS_TOKEN_FOR_OTHER';
export const ACTION_TESTER_PAGE_CHANGE_EXAM_STATUS: string = 'testerPage/ACTION_TESTER_PAGE_CHANGE_EXAM_STATUS';
export const ACTION_TESTER_PAGE_CHANGE_TEST_STATUS: string = 'testerPage/ACTION_TESTER_PAGE_CHANGE_TEST_STATUS';
export const ACTION_TESTER_PAGE_START_TESTER_RECORDING: string = 'testerPage/ACTION_TESTER_PAGE_START_TESTER_RECORDING';
export const ACTION_TESTER_PAGE_END_TESTER_RECORDING: string = 'testerPage/ACTION_TESTER_PAGE_END_TESTER_RECORDING';
export const ACTION_TESTER_PAGE_END_TESTER_RECORDING_RECOVERY: string =
  'testerPage/ACTION_TESTER_PAGE_END_TESTER_RECORDING_RECOVERY';
export const ACTION_TESTER_PAGE_GET_TESTER_RECORDING: string = 'testerPage/ACTION_TESTER_PAGE_GET_TESTER_RECORDING';
export const ACTION_TESTER_PAGE_GET_TEST_MARKINGS: string = 'testerPage/ACTION_TESTER_PAGE_GET_TEST_MARKINGS';
export const ACTION_TESTER_PAGE_GET_TEST_MARKINGS_LATEST: string =
  'testerPage/ACTION_TESTER_PAGE_GET_TEST_MARKINGS_LATEST';
// 「AI本人認証」画面
export const ACTION_TESTER_PAGE_IDENTITYIMAGE: string = 'testerPage/ACTION_TESTER_PAGE_IDENTITYIMAGE';
export const ACTION_TESTER_PAGE_ID_IMAGE: string = 'testerPage/ACTION_TESTER_PAGE_ID_IMAGE';
export const ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY: string = 'testerPage/ACTION_TESTER_PAGE_INIT_FIXED_AI_DISPLAY';
export const ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY_CHECK: string =
  'testerPage/ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY_CHECK';
export const ACTION_TESTER_PAGE_NOTICE_AI_DISPLAY: string = 'testerPage/ACTION_TESTER_PAGE_NOTICE_AI_DISPLAY';
export const ACTION_TESTER_PAGE_COUNT_RETRY_NUM: string = 'testerPage/ACTION_TESTER_PAGE_COUNT_RETRY_NUM';
export const ACTION_TESTER_PAGE_INIT_AI_IDENTIFY: string = 'testerPage/ACTION_TESTER_PAGE_INIT_AI_IDENTIFY';
export const ACTION_TESTER_PAGE_TESTER_AUTHENTICATED: string = 'testerPage/ACTION_TESTER_PAGE_TESTER_AUTHENTICATED';
