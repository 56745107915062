// Getters
export const GETTER_DELETE_SETTING_PAGE_GET_INDEX_LIST: string =
  'deleteSettingPage/GETTER_DELETE_SETTING_PAGE_GET_INDEX_LIST';

// Mutations
export const MUTATION_DELETE_SETTING_PAGE_SET_INDEX: string =
  'deleteSettingPage/MUTATION_DELETE_SETTING_PAGE_SET_INDEX';

// Actions
export const ACTION_DELETE_SETTING_PAGE_GET_INDEX: string = 'deleteSettingPage/ACTION_DELETE_SETTING_PAGE_GET_INDEX';
export const ACTION_DELETE_SETTING_PAGE_POST_STORE: string = 'deleteSettingPage/ACTION_DELETE_SETTING_PAGE_POST_STORE';
export const ACTION_DELETE_SETTING_PAGE_POST_UPDATE: string =
  'deleteSettingPage/ACTION_DELETE_SETTING_PAGE_POST_UPDATE';
