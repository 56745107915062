
















// https://e-coms.backlog.jp/view/AI_PROCTOR-2368
import Vue, { PropType } from 'vue';
import { NuxtError } from '@nuxt/types';
import { JapaneseLanguageEnum } from '@/store/enum/language';

export default Vue.extend({
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },

  computed: {
    errorMessage(): string {
      const { message } = this.error;
      if (!message || shouldUseCustomDefaultMsg(message)) {
        return JapaneseLanguageEnum.BAD_REQUEST_ALERT_TEXT;
      }
      return message;
    },
  },

  head() {
    return {
      title: 'エラー',
    };
  },
});

function shouldUseCustomDefaultMsg(message?: string): boolean {
  // https://github.com/nuxt/nuxt/issues/8375
  const nuxtDefaultMsg = 'This page could not be found';
  if (message) {
    if (message === nuxtDefaultMsg) return true;
    try {
      // message maybe "{\n  "statusCode": 404\n}"
      const { statusCode } = JSON.parse(message);
      if (typeof statusCode === 'number') return true;
    } catch (e) {}
  }
  return false;
}
