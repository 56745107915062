// Getters
export const GETTER_COMMON: string = 'common/GETTER_COMMON';
export const GETTER_COMMON_IS_LAYOUT_VISIBLE: string = 'common/GETTER_COMMON_IS_LAYOUT_VISIBLE';
export const GETTER_COMMON_GET_HEADER_LOGOUT_BUTTON_DISABLED: string =
  'common/GETTER_COMMON_GET_HEADER_LOGOUT_BUTTON_DISABLED';
export const GETTER_COMMON_IS_LOADING: string = 'common/GETTER_COMMON_IS_LOADING';
export const GETTER_COMMON_IS_LOADING_ALERT_VISIBLE: string = 'common/GETTER_COMMON_IS_LOADING_ALERT_VISIBLE';
export const GETTER_COMMON_IS_UPLOADING_ALERT_VISIBLE: string = 'common/GETTER_COMMON_IS_UPLOADING_ALERT_VISIBLE';

// Mutations
export const MUTATION_COMMON_SET_LAYOUT_VISIBLE: string = 'common/MUTATION_COMMON_SET_LAYOUT_VISIBLE';
export const MUTATION_COMMON_SET_HEADER_LOGOUT_BUTTON_DISABLED: string =
  'common/MUTATION_COMMON_SET_HEADER_LOGOUT_BUTTON_DISABLED';
export const MUTATION_COMMON_SET_LOADING: string = 'common/MUTATION_COMMON_SET_LOADING';
export const MUTATION_COMMON_SET_LOADING_ALERT: string = 'common/MUTATION_COMMON_SET_LOADING_ALERT';

// Actions
export const ACTION_COMMON_SET_LAYOUT_VISIBLE: string = 'common/ACTION_COMMON_SET_LAYTOUT_VISIBLE';
export const ACTION_COMMON_SET_HEADER_LOGOUT_BUTTON_DISABLED: string =
  'common/ACTION_COMMON_SET_HEADER_LOGOUT_BUTTON_DISABLED';
export const ACTION_COMMON_SET_LOADING: string = 'common/ACTION_COMMON_SET_LOADING';
export const ACTION_COMMON_SET_LOADING_ALERT: string = 'common/ACTION_COMMON_SET_LOADING_ALERT';
