export default `　
以下の内容をすべてお読みいただき、同意の上、利用をお願いします。
　
はじめに
●	リモートテスティングでの受験にあたっては、リモートテスティングの利用規約（https://www.remote-testing.jp/terms/）、ならびに、試験の申込にあたり提示された他の規約、試験実施団体から提示された受験規約等を遵守いただくこととします。これら規約等に従わない場合、受験は無効となるとともに、各規約に定められた罰則が適用されることとなります。また、再受験の可否は試験実施団体の判断によります。また、再受験が可能となった場合も、その受験費用は原則として受験者の負担となります。
　
受験に使用する機器について
●	PC等の機器に付属のカメラ（以下、「Webカメラ」と呼ぶ）を、受験状態の記録に使用します。顔全体が正面から映るように位置を調整をしてください。
●	「YouCam」「Snap Camera」等の、映像加工を伴うソフトウェアや、背景画像を加工する機能を持つハードウェアは使用してはいけません。
●	受験画面を録画／キャプチャするソフトウェアや機器を使用してはいけません。
●	PCのモニターは１つのみとします。複数のモニターは使用できません。
　
映像・音声の利用について
●	本システムでは、Webカメラ映像、マイク音声を取得し、保存します。保存した映像、音声は、受験が適正に行われたかの確認、受験環境の確認、不正の有無等の確認に使われるとともに、本サービスの品質改善のために使用されます。
●	映像、音声は、試験実施団体に提供されます。また、団体経由での申込の場合、申込団体に提供される場合があります。
　
受験環境について
●	受験する場所には、他の人が入ってこないようにしてください。
●	携帯電話は、電源をオフにして、ご自身の視界に入らないところに置いてください。
●	受験中は、試験実施団体より使用が許可されている場合のみ、電卓、テキスト、メモ用紙等の使用が可能です。
●	受験する机の上には、書類、本などは置かず、視界に入らないところに置いてください。
●	文字が書かれたポスター等の掲示物、書籍（タイトルのみも含む）は、外して視界に入らないようにするか、カバーをかけてください。
●	テレビ、ラジオ、音楽などの音は消してください。
●	受験に使用するモニター以外のモニター、テレビなど映像を映すことができる機器は、映像が視界に入らないように向きを変えるかカバーをかけてください。
●	試験監督からの指示があった場合、Webカメラを回して、受験する部屋と机の360度録画を作成します。ノートPCの内蔵Webカメラの場合など、本体に固定されている場合には、本体ごと回せるように、電源コード等の状態を確認してください。
●	試験監督からの指示があった場合、Web カメラ を使って あなたの顔と本人確認書を撮影して保存します。本人確認書に記載された、本人確認に必要な情報（顔写真、氏名、生年月日）以外のプライバシー情報（マイナンバー、本籍地、マイナンバーカードの臓器提供意思表示欄など）を含む部分は、テープ等であらかじめマスクしておいてください。
●	サングラス、マスク等は、本人確認や受験状況の確認に支障が出ますので、本人確認の際、ならびに、受験中は外してください。
●	受験画面を撮影または録画する機器（ペット監視用のカメラ等も含む）が無いことを確認してください。ある場合には、受験画面が映らないようにカバーをしてください。
●	受験する場所には、ラベルがなく、倒してもこぼれない容器に入った飲み物のみ持ち込み可能です。
●	受験中に食べ物を摂取することはできません。健康上の理由により、食べ物、薬品等を受験中にとる必要がある場合は、あらかじめ試験実施団体に連絡して許可を得てください。
　
試験監督からの指示について
●	受験システム、または、試験監督からの指示は、画面上に表示されます。
●	その他、試験監督から画面上に指示があった場合は、その指示に従っていただく必要があります。
　
以下のチェックボックスにチェックして「次へ」を押すと、Webカメラの映像と音声の送信を開始します。
`;
