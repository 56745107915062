/* eslint-disable no-console */
import AWS from 'aws-sdk';
import { Plugin, Context } from '@nuxt/types';
import * as rootTypes from '@/store/types/rootType';
import * as testerPageTypes from '@/store/types/testerPageType';
import {
  TesterRecordingAdapter,
  // TesterRecordingRecoveryAdapter,
  // TesterRecordingRecoveryRequestType,
} from '@/store/types/adapters/testerRecordingAdapter';
import { Formatter } from '@/utils/Formatter';
import { PromiseUtils } from '@/utils/PromiseUtils';
import { valid } from '@/utils/string-tools';

declare const MediaRecorder: any; // TODO: use @types/web after update ts version
declare module 'vue/types/vue' {
  interface Vue {
    $startUploader(stream: MediaStream, eventHandler: UploaderEventHandler, options: optionsType): Promise<boolean>;
    $stopUploader(): Promise<boolean>;
    $ugUploadCompleteHandler(): Promise<void>;
    $ugUploadGetS3Configs(): Promise<s3ConfigsType>;
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $startUploader(stream: MediaStream, eventHandler: UploaderEventHandler, options: optionsType): Promise<boolean>;
    $stopUploader(): Promise<boolean>;
    $ugUploadCompleteHandler(): Promise<void>;
    $ugUploadGetS3Configs(): Promise<s3ConfigsType>;
  }
  interface Context {
    $startUploader(stream: MediaStream, eventHandler: UploaderEventHandler, options: optionsType): Promise<boolean>;
    $stopUploader(): Promise<boolean>;
    $ugUploadCompleteHandler(): Promise<void>;
    $ugUploadGetS3Configs(): Promise<s3ConfigsType>;
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $startUploader(stream: MediaStream, eventHandler: UploaderEventHandler, options: optionsType): Promise<boolean>;
    $stopUploader(): Promise<boolean>;
    $ugUploadCompleteHandler(): Promise<void>;
    $ugUploadGetS3Configs(): Promise<s3ConfigsType>;
  }
}

export type s3ConfigsType = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  bucket: string;
  key: string;
  uploadId: string;
};

type optionsType = {
  mimeType?: string;
  audioBitsPerSecond: number;
  videoBitsPerSecond: number;
  timeslice: number;
  videoRecordingPreference?: number;
  voiceQualityPreference?: number;
};

export type UploaderEvent = {
  error?: Error;
  errorString?: string;
};

export type UploaderEventHandler = {
  initializeError: (event: UploaderEvent) => Promise<boolean>;
  uploadError: (event: UploaderEvent) => Promise<boolean>;
  stopped: () => void;
};

type UploadPart = {
  PartNumber: number;
  Completed: boolean;
  Errored: boolean;
  data: Blob;
};

type UploadStatus = {
  readonly maxUploadingNum: number;
  currUploadingNum: number;
};

type LoggingParams = {
  module?: string;
  location?: string;
  type?: number;
  path?: string;
  timestamp?: string;
  tag1?: number;
  details?: string;
};

type DataAvailableErrHandler = {
  error: any;
  retry: number;
  networkErrTimes: number[];
  uploadMethodText: string;
  partNumber: number;
  currPartIndex: number;
};

type DataAvailableRetry = {
  uploadMethodLocation: string;
  uploadMethodText: string;
  uploadToS3: (blob: Blob, partNum: number) => Promise<AWS.S3.UploadPartOutput>;
};

/**
 * S3 timeout (aws-sdk)
 * sdk's default timeout is 2 min
 * https://github.com/aws/aws-sdk-js/issues/949#issuecomment-204178782
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#constructor_details
 */
const timeout = 0; // ms
const MINIMUM_SIZE = 1048576 * 5; // 5MB 制限
const logPath = '/proctor/front/plugins/s3/upload.ts';
const debugLevel = 0;
const networkErrLimitTime = 60; // 限界値：60分以上になったらリトライを終了
let loggerContext: Context;
let loggerStartTime: Date;

function logging(isDebug: number, params: LoggingParams, logDetails = false, consoleError = false) {
  try {
    const context: Context = loggerContext;
    const _isDebug = context?.app?.store?.getters[rootTypes.GETTER_STARTUP].isDebug;
    if (_isDebug >= isDebug) {
      const endTime = new Date();
      params.type = 5;
      params.path = logPath;
      params.timestamp = Formatter.date('yyyy-MM-dd hh:mm:ss', endTime);
      params.tag1 = endTime.getTime() - loggerStartTime.getTime();
      context.app.$axios.$post('/api/v1/log_receive.php', params);
    }
    if (logDetails) {
      if (consoleError) console.error(params.details);
      else console.log(params.details);
    }
  } catch (e) {
    // このエラーは握りつぶす
  }
}

/**
 * S3へのアクセスを行うためのクラスです
 * 初期化処理/終了処理をサーバ側、動画アップロードをブラウザ側で行っています。
 */
class S3Component {
  public context!: Context;
  public s3Client!: AWS.S3;
  public bucketName!: string;
  public key!: string;
  public uploadId!: string;

  constructor(context: Context) {
    this.context = context;
  }

  // S3の設定を取得します
  async getS3Configs(context: Context): Promise<s3ConfigsType> {
    try {
      const recording: TesterRecordingAdapter = await context.app.store?.dispatch(
        testerPageTypes.ACTION_TESTER_PAGE_START_TESTER_RECORDING
      );
      logging(debugLevel, {
        module: 'getS3Configs',
        location: 'S3Configs get',
        details: `bucket:${recording.bucket} key:${recording.key} uploadId:${recording.uploadId}`,
      });
      return {
        accessKeyId: valid(recording.accessKeyId),
        secretAccessKey: valid(recording.secretAccessKey),
        bucket: valid(recording.bucket),
        key: valid(recording.key),
        sessionToken: valid(recording.sessionToken),
        uploadId: valid(recording.uploadId),
      };
    } catch (error) {
      console.error('[getS3Configs]', error);
      throw error;
    }
  }

  // S3を初期化します
  async initS3(context: Context): Promise<s3ConfigsType> {
    try {
      if (!this.context) this.context = context;
      const s3Configs = await this.getS3Configs(this.context);
      // Configure the AWS. In this case for the simplicity I'm using access key and secret.
      AWS.config.update({
        credentials: {
          accessKeyId: s3Configs.accessKeyId,
          secretAccessKey: s3Configs.secretAccessKey,
          sessionToken: s3Configs.sessionToken,
        },
      });
      this.s3Client = new AWS.S3({ httpOptions: { timeout }, region: 'ap-northeast-1' });
      this.bucketName = s3Configs.bucket;
      this.key = s3Configs.key;
      console.log(`-----------------config:uploadId${s3Configs.uploadId}`);
      this.uploadId = s3Configs.uploadId;
      logging(debugLevel, {
        module: 'initS3',
        location: 'S3Configs set',
        details: `bucket:${this.bucketName} key:${this.key} uploadId:${this.uploadId}`,
      });
      return s3Configs;
    } catch (error) {
      console.error('[initS3]', error);
      throw error;
    }
  }

  /**
   * S3の後始末処理をします。
   * シングルパートアップロードでもマルチアップロードでもサーバ側で適切に行います
   */
  finalS3(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.context.app.store) {
        // 認証エラーがあるか？
        (this.context.app.store.getters[testerPageTypes.GETTER_TESTER_PAGE_IS_AUTH_ERROR]
          ? // ? (this.context as any).app.store.dispatch(testerPageTypes.ACTION_TESTER_PAGE_END_TESTER_RECORDING_RECOVERY,
            // {key: this.key} as TesterRecordingRecoveryRequestType)
            Promise.resolve(true)
          : this.context.app.store.dispatch(testerPageTypes.ACTION_TESTER_PAGE_END_TESTER_RECORDING)
        )
          .then(() => {
            resolve(true);
          })
          .catch(() => resolve(true));
        // .catch((error: any) => reject(error))
      }
    });
  }

  /**
   * S3にアップロードします
   */
  putObjectVideo(blob: Blob): Promise<AWS.S3.PutObjectOutput> {
    return new Promise((resolve, reject) => {
      this.s3Client
        .putObject({
          Bucket: this.bucketName,
          Body: blob,
          Key: this.key,
          ContentType: blob.type,
        })
        .promise()
        .then((output: AWS.S3.PutObjectOutput) => {
          console.log(`File uploaded successfully. ${output}`);
          resolve(output);
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * マルチパートアップロードのパーツをS3にアップロードします
   */
  uploadPartVideo(blob: Blob, partNumber: number): Promise<AWS.S3.UploadPartOutput> {
    console.log(`s3Obj.uploadId:${this.uploadId}`);
    return new Promise((resolve, reject) => {
      this.s3Client
        .uploadPart({
          Body: blob,
          Bucket: this.bucketName,
          Key: this.key,
          PartNumber: partNumber,
          UploadId: this.uploadId,
          ContentLength: blob.size,
        })
        .promise()
        .then((output: AWS.S3.UploadPartOutput) => resolve(output))
        .catch((err) => reject(err));
    });
  }

  //  stopHandler(): Promise<boolean> {
  //    try {
  //      const { Location } = await this.uploadComplete(this.multiParts);
  //      console.log('upload success : ', Location);
  //      logging(debugLevel, {module:'stopHandler','location':'upload success','details':'upload success:'+Location});
  //      this.partNumber = 0;
  //      this.multiParts = [];
  //      return Promise.resolve(true);
  //    } catch (error) {
  //      console.error(error);
  //      logging(debugLevel, {module:'stopHandler','location':'upload error','details':'upload error:'+error+' abortMultipartUploadへ'});
  //      (this.s3Client as any)
  //        .abortMultipartUpload({
  //          Bucket: this.bucketName,
  //          UploadId: this.uploadId,
  //          Key: this.key
  //        })
  //        .promise()
  //        .then(() => console.log('Multipart upload aborted'))
  //        .catch((e: any) => {
  //          console.error(e);
  //          logging(debugLevel, {module:'stopHandler','location':'abortMultipartUpload error','details':'abortMultipartUpload error:'+e});
  //          this.eventHandler.abortError({error: e, errorString: e.toString()});
  //        });
  //      return Promise.reject(error);
  //    }
  //  }
  //
  //  uploadComplete(parts: multiPartsType[]) {
  //    console.log(parts);
  //    logging(debugLevel, {module:'uploadComplete','location':'uploadComplete','details':'この処理は動いたらいけない'});
  //    return (this.s3Client as any)
  //      .completeMultipartUpload({
  //        Bucket: this.bucketName,
  //        Key: this.key,
  //        UploadId: this.uploadId,
  //        MultipartUpload: {
  //          Parts: this.multiParts
  //        }
  //      })
  //      .promise();
  //  }
}

/**
 * 録画をS3にアップロードするためのレコーダ
 * このクラスのstartUploader()とstopUploader()の実行は排他的に行われます。
 * それぞれのメソッドから返されたPromiseが完全に完了し通知を行うまでは、どちらかのメソッドが動くことはありません。
 */
class S3Uploader {
  public s3Component!: S3Component;
  // TODO: use @types/web after update ts version
  public mediaRecorder: any /* MediaRecorder | null */;
  public defaultOptions!: optionsType;
  // この2つのpromiseでstartUploader()とstopUploader()が同時に実行されないよう制御する
  public startUploaderPromise!: Promise<boolean> | null;
  public stopUploaderPromise!: Promise<boolean> | null;
  public aborts: { error: any }[] = [];
  public partNumber: number = 0;
  public stopPartNumber: number | null = null;
  public uploadParts: UploadPart[] = [];
  public lastBlob: Blob[] = [];
  // https://e-coms.backlog.jp/view/AI_PROCTOR-2106#comment-167550561
  public isLastPartStartedUploading: boolean = false;
  public uploadStatus: UploadStatus = {
    maxUploadingNum: 1,
    currUploadingNum: 0,
  };

  public eventHandler: UploaderEventHandler = {
    initializeError: (_event: UploaderEvent) => {
      return Promise.resolve(true);
    },
    uploadError: (_event: UploaderEvent) => {
      return Promise.resolve(true);
    },
    stopped: () => {},
  };

  constructor(context: Context) {
    this.initUploader(this.eventHandler, context);
  }

  private initUploader(eventHandler: UploaderEventHandler, context: Context) {
    loggerStartTime = new Date();
    this.s3Component = new S3Component(context);
    this.mediaRecorder = null;
    this.defaultOptions = {
      // https://www.mux.com/blog/how-to-use-mediarecorder#step-3-pass-the-stream-to-the-mediarecorder
      mimeType: context?.app?.store?.getters[rootTypes.GETTER_STARTUP]?.videoCodec, // kickRT設定
      videoBitsPerSecond: 5000000, // 5000000 bits/s
      audioBitsPerSecond: 128000, // 128000 bits/s
      timeslice: 15000, // 15sec
    };
    this.eventHandler = eventHandler;
    this.startUploaderPromise = null;
    this.stopUploaderPromise = null;
    this.aborts = [];
    this.partNumber = 0;
    this.stopPartNumber = null;
    this.uploadParts = [];
    this.lastBlob = [];
  }

  private startUploaderCore(
    stream: MediaStream,
    options: optionsType,
    resolve: (value?: boolean | PromiseLike<boolean> | undefined) => void,
    reject: (reason?: any) => void,
    context: Context
  ) {
    try {
      logging(debugLevel, {
        module: 'startUploader',
        location: '初期化スタート',
        details: 's3Objの初期化開始',
      });
      this.mediaRecorder = new MediaRecorder(stream, this.setOptions(options));
      logging(debugLevel, {
        module: 'startUploader',
        location: 'MediaRecorder created',
        details: 'MediaRecorder:作成成功',
      });
      this.mediaRecorder.addEventListener('start', (event: any) => this.startHandler(event));
      this.mediaRecorder.addEventListener('stop', (event: any) => this.stopHandler(event));
      this.mediaRecorder.addEventListener('dataavailable', (event: any) => this.dataAvailableHandler(event));
      this.mediaRecorder.start(this.defaultOptions.timeslice); // timeslice
      logging(debugLevel, {
        module: 'startUploader',
        location: 'MediaRecorder start',
        details: 'MediaRecorder:スタート',
      });
      this.startUploaderPromise = null;
      resolve(true);
    } catch (e) {
      logging(
        debugLevel,
        {
          module: 'startUploader',
          location: 'MediaRecorder error',
          details: `Exception while creating MediaRecorder: ${e} ストップイベントへ`,
        },
        true,
        true
      );
      this.eventHandler.initializeError({ error: e, errorString: e.toString() });
      this.mediaRecorder = null;
      this.startUploaderPromise = null;
      context.app.$modals.showErrorAlert('録画開始に失敗しました。').finally(() => reject(e));
    }
  }

  /**
   * アップロードを開始します
   */
  startUploader(
    context: Context,
    stream: MediaStream,
    eventHandler: UploaderEventHandler,
    options: optionsType
  ): Promise<boolean> {
    if (this.startUploaderPromise) {
      // すでにstartUploader()中ならばなにもしない
      return this.startUploaderPromise;
    } else if (this.stopUploaderPromise) {
      // stopUploader()中ならば、処理終了を待ってstartUploader()を行う
      return new Promise((resolve, reject) => {
        (this.stopUploaderPromise as Promise<boolean>).finally(() => {
          this.startUploader(context, stream, eventHandler, options).then(resolve).catch(reject);
        });
      });
    }
    return (this.startUploaderPromise = new Promise((resolve, reject) => {
      this.initUploader(eventHandler, context);
      // 処理が成功するまで、繰り返します
      PromiseUtils.repeatUntilSuccessful(() => this.s3Component.initS3(context), {})
        .then(() => this.startUploaderCore(stream, options, resolve, reject, context))
        .catch((e: any) => {
          // S3Componentの初期化失敗
          this.mediaRecorder = null;
          this.startUploaderPromise = null;
          reject(e);
        });
    }));
  }

  stopUploaderCore(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let isUploadStopped: boolean = false;
      let retry: number = 0;
      const interval = setInterval(() => {
        try {
          if (!isUploadStopped) {
            retry = 0;
            // ストップ処理開始
            console.log('------------------------------------------------------stopUploader');
            this.mediaRecorder.stop();
            isUploadStopped = true;
            logging(debugLevel, {
              module: 'stopUploader',
              location: 'stopped',
              details: 'stopUploader',
            });
          } else {
            retry = this.stopUploaderRetryLoggingBeforeUpdated(retry, interval, resolve);
          }
        } catch (error) {
          logging(
            debugLevel,
            {
              module: 'stopUploader',
              location: 'stop error',
              details: `stop error:${error}`,
            },
            true,
            true
          );
        }
      }, 1000);
    });
  }

  private stopUploaderRetryLoggingBeforeUpdated(
    retry: number,
    interval: NodeJS.Timeout,
    resolve: (value?: boolean | PromiseLike<boolean> | undefined) => void
  ) {
    retry++;
    if (this.stopPartNumber === null) {
      // stopイベント発火まで何もしない
      logging(debugLevel, {
        module: 'stopUploader',
        location: `wait stop event retry:${retry}`,
        details: `アップロードが完了するまでリトライ:${retry}`,
      });
    } else {
      const isSinglepartUpload = this.stopPartNumber === 1;
      const uploadMethodText = isSinglepartUpload ? 'シングルパートアップロード' : 'マルチパートアップロード';
      const process = this.aborts.length
        ? {
            // すべてのパーツのアップロード完了かエラー完了か？
            isEnd: () =>
              this.uploadParts.filter((status) => status.Completed || status.Errored).length === this.stopPartNumber,
            processText: 'アボート',
          }
        : {
            // すべてのパーツのアップロード完了か？
            isEnd: () => this.uploadParts.filter((status) => status.Completed).length === this.stopPartNumber,
            processText: '通常アップロード',
          };
      // すべてのパーツのアップロード完了かエラーを待つ
      if (process.isEnd()) {
        logging(debugLevel, {
          module: 'stopUploader',
          location: 'stopped completed:',
          details: `${uploadMethodText}が${process.processText}が完了`,
        });
        clearInterval(interval);
        resolve(false);
      } else {
        logging(debugLevel, {
          module: 'stopUploader',
          location: `stopped retry:${retry}`,
          details: `${uploadMethodText}が${process.processText}が完了するまでリトライ:${retry}`,
        });
      }
    }
    return retry;
  }

  /**
   * アップロードを終了します
   * @param abort
   * この引数を指定した場合、アップロード処理を正常終了せず異常終了とします。
   * ただし、処理を中断はせず、アップロード完了とエラー検知がすべて完了するのを待ってから終了します。
   */
  stopUploader(abort: { error: any } | null = null): Promise<boolean> {
    if (abort) {
      this.aborts.push(abort);
    }
    if (this.startUploaderPromise) {
      // startUploader()中ならば処理終了後にストップを行う
      return new Promise((resolve, reject) => {
        (this.startUploaderPromise as Promise<boolean>).finally(() => {
          this.stopUploader(abort).then(resolve).catch(reject);
        });
      });
    } else if (this.stopUploaderPromise) {
      // すでにstopUploader()中ならば、なにもしない
      return this.stopUploaderPromise;
    }
    if (!this.mediaRecorder) {
      return Promise.resolve(true);
    }
    return (this.stopUploaderPromise = new Promise<boolean>((resolve) => {
      this.stopUploaderCore().then((result) => {
        // 処理が成功するまで、繰り返します
        PromiseUtils.repeatUntilSuccessful(() => (this.s3Component as S3Component).finalS3(), {}).then(() => {
          this.mediaRecorder = null;
          this.stopUploaderPromise = null;
          if (this.aborts.length) {
            const e = this.aborts[0].error;
            this.eventHandler.uploadError({ error: e, errorString: e.toString() });
          }
          resolve(result);
        });
      });
    }));
  }

  /**
   * UltraGuardian 用：S3 設定を取得します
   */
  ugUploadGetS3Configs(): Promise<s3ConfigsType> {
    return PromiseUtils.repeatUntilSuccessful(() => this.s3Component.getS3Configs(this.s3Component.context), {});
  }

  /**
   * UltraGuardian のアップロード完了イベントのハンドラ
   */
  ugUploadCompleteHandler(): Promise<void> {
    // NOTE: finalS3 は例外だろうと完了扱いとなっている. これは問題ないのか?
    return PromiseUtils.repeatUntilSuccessful(() => this.s3Component.finalS3(), {});
  }

  // mediaRecorder.onstart
  startHandler(_event: any) {
    // nop
  }

  // mediaRecorder.onstop
  stopHandler(_event: any) {
    if (this.stopPartNumber === null) {
      this.stopPartNumber = this.partNumber;
    }
    this.eventHandler.stopped();
  }

  private processBeforeUploadData(isSinglepartUpload: boolean, mediaRecorderState: string, totalSize: number) {
    // シングルアップロードで処理する場合は、そのまま処理する
    if (isSinglepartUpload) {
      logging(
        debugLevel,
        {
          module: 'dataAvailableHandler',
          location: 'single part upload start',
          details:
            `ストップイベント中でpartsが1なのでputObjectでシングルアップロードする part${this.partNumber + 1},` +
            `ダウンロード間隔:${this.defaultOptions.timeslice}, blob size:${totalSize}`,
        },
        true
      );
    }
    // マルチアップロードで処理する場合は、最後のパート以外の5M未満のアップロードができないので5M未満である場合、
    // 次のdataAvailableHandlerイベント発火に任せる。
    // ただし、最後のパートについては、サイズの制限はありません。
    // @see https://docs.aws.amazon.com/ja_jp/AmazonS3/latest/dev/qfacts.html
    else if (mediaRecorderState === 'inactive') {
      // 最後のパート
      this.isLastPartStartedUploading = true;
      logging(
        debugLevel,
        {
          module: 'dataAvailableHandler',
          location: 'multi part upload',
          details:
            `マルチパートアップロード最後のパートアップロード part${this.partNumber + 1}, ` +
            `ダウンロード間隔:${this.defaultOptions.timeslice}, blob size:${totalSize}`,
        },
        true
      );
    }
    // 最後以外のパート
    else if (totalSize >= MINIMUM_SIZE) {
      // 最後以外のパート: 5M以上
      logging(
        debugLevel,
        {
          module: 'dataAvailableHandler',
          location: 'multi part upload',
          details:
            `マルチパートアップロード最後以外のパートアップロード part${this.partNumber + 1},` +
            `ダウンロード間隔:${this.defaultOptions.timeslice}, blob size:${totalSize}`,
        },
        true
      );
    }
  }

  // mediaRecorder.ondataavailable
  async dataAvailableHandler(event: any) {
    if (this.isLastPartStartedUploading) return;

    this.lastBlob.push(event.data);
    // このメソッドは同期的に実行されていないため、このメソッド内でthis.mediaRecorder.stateを参照してはいけない。
    // mediaRecorderStateを使うこと
    const mediaRecorderState = this.mediaRecorder.state;
    // StopとpartNumber===0ならputObjectで一回だけアップロードする
    // この条件については、十分留意すること。stopイベント発火前の一回起こる。
    // ステータスの変更、イベントの発火順序については以下参照
    // @see https://developer.mozilla.org/ja/docs/Web/API/MediaRecorder/stop
    const isSinglepartUpload = mediaRecorderState === 'inactive' && this.partNumber === 0;
    const uploadMethodLocation = isSinglepartUpload ? 'single parts upload' : 'multi parts upload';
    const uploadMethodText = isSinglepartUpload ? 'シングルパートアップロード' : 'マルチパートアップロード';
    const totalSize = this.lastBlob.reduce((total, v) => total + v.size, 0);
    const shouldContinue = isSinglepartUpload || mediaRecorderState === 'inactive' || totalSize >= MINIMUM_SIZE;

    if (shouldContinue) this.processBeforeUploadData(isSinglepartUpload, mediaRecorderState, totalSize);
    else return; // 最後以外のパート: 5M未満（次のdataAvailableHandlerイベント発火に任せる）

    this.partNumber++;
    const data = this.lastBlob.length > 1 ? new Blob(this.lastBlob, { type: this.lastBlob[0].type }) : this.lastBlob[0];
    this.lastBlob = [];
    this.uploadParts.push({ PartNumber: this.partNumber, Completed: false, Errored: false, data });
    const uploadToS3 = isSinglepartUpload
      ? async (blob: Blob, _partNum: number) => await (this.s3Component as S3Component).putObjectVideo(blob)
      : async (blob: Blob, partNum: number) => await (this.s3Component as S3Component).uploadPartVideo(blob, partNum);

    await this.dataAvailableRetry({ uploadMethodLocation, uploadMethodText, uploadToS3 });
  }

  private async dataAvailableRetry({ uploadMethodLocation, uploadMethodText, uploadToS3 }: DataAvailableRetry) {
    let retry = 0;
    // 毎回ネットワークエラーの発生時点、この配列にあつめられます
    const networkErrTimes: number[] = [];
    while (
      this.uploadStatus.currUploadingNum < this.uploadStatus.maxUploadingNum &&
      !this.uploadParts.every((p) => p.Completed || p.Errored)
    ) {
      let currPartIndex; // 今のpartは、必ず上pushまれたpartとは限らないので、注意してください
      let partNumber; // Whileループ内のpartNumberは外のthis.partNumberと違うので、注意してください
      ({ currPartIndex, partNumber, retry } = this.getRetryStatus(retry, uploadMethodLocation, uploadMethodText));
      try {
        await this.dataAvailableUploader(uploadToS3, currPartIndex, partNumber, uploadMethodLocation, uploadMethodText);
      } catch (error) {
        const shouldFinishRetry = await this.dataAvailableErrHandler({
          error,
          retry,
          networkErrTimes,
          uploadMethodText,
          partNumber,
          currPartIndex,
        });
        if (shouldFinishRetry) return;
      }
    }
  }

  /**
   * @return shouldFinishRetry
   */
  private async dataAvailableErrHandler(params: DataAvailableErrHandler): Promise<boolean> {
    this.uploadStatus.currUploadingNum--;
    const { error, retry, uploadMethodText, partNumber, currPartIndex } = params;
    const isNetworkingError = error.code === 'NetworkingError' || error.name === 'NetworkingError';
    const isTimeoutError = error.code === 'TimeoutError' || error.name === 'TimeoutError';
    const isTimeoutErrorAndShoudRetry = isTimeoutError && retry <= 10;
    if (isNetworkingError) {
      return await this.handleNetworkingError(params);
    } else if (isTimeoutErrorAndShoudRetry) {
      // タイムアウトエラー(リトライ回数10回以下)の場合は、アップロードを試みる
      return this.handleTimeoutError(error, uploadMethodText, partNumber, retry);
    } else {
      // ネットワークエラー/タイムアウトエラー（リトライ回数10回以下）以外
      return this.handleOtherError(error, partNumber, currPartIndex);
    }
  }

  /**
   * @return shouldFinishRetry
   */
  private handleOtherError(error: any, partNumber: number, currPartIndex: number) {
    logging(debugLevel, {
      module: 'dataAvailableHandler',
      location: 'error!!',
      details: `error code:${error.code}:${error.name} ストップイベント実行 part:${partNumber}`,
    });
    this.uploadParts[currPartIndex].Errored = true;
    // エラーが一度でも発生した場合、対処法がない(わからない)
    // なのでこのマルチパートアップロード(or シングルパートアップロード)をアボートします
    this.stopUploader({ error });
    return true;
  }

  /**
   * @return shouldFinishRetry
   */
  private async handleNetworkingError({
    networkErrTimes,
    error,
    uploadMethodText,
    partNumber,
    currPartIndex,
    retry,
  }: DataAvailableErrHandler) {
    // ネットワークエラーが発生した時点(単位: 分)、networkErrTimeの配列に追加します
    const { duration, min, max, isOverLimit } = this.checkNetworkErrTimeStatus(networkErrTimes);
    // もし持続時間 >= 60分 リトライを終了します
    if (isOverLimit) {
      logging(debugLevel, {
        module: 'dataAvailableHandler',
        location: 'NetworkingError!!',
        details:
          `error code:${error.code}:${error.name}` +
          ` ${uploadMethodText}ステータス part:${partNumber}, ` +
          `min:${min}, max: ${max}, duration: ${duration}, minTime: ${networkErrLimitTime}`,
      });
      // アップロード完了とエラー検知がすべて完了するのを待ってから終了します。
      this.uploadParts[currPartIndex].Errored = true;
      this.stopUploader({ error });
      return true;
    }
    // ネットワークエラーの場合は、処理を再度アップロードを試みる
    logging(debugLevel, {
      module: 'dataAvailableHandler',
      location: 'NetworkingError!!',
      details: `error code:${error.code}:${error.name} ${uploadMethodText}ステータス part:${partNumber}, retry:${retry}`,
    });
    // ネットワークエラーでAPI投げ続けると重くなるのでsleepする
    await PromiseUtils.sleep(1000);
    return false;
  }

  /**
   * @return shouldFinishRetry
   */
  private handleTimeoutError(error: any, uploadMethodText: string, partNumber: number, retry: number) {
    logging(debugLevel, {
      module: 'dataAvailableHandler',
      location: 'TimeoutError!!',
      details: `error code:${error.code}:${error.name} ${uploadMethodText}ステータス part:${partNumber}, retry:${retry}`,
    });
    return false;
  }

  private checkNetworkErrTimeStatus(networkErrTimes: number[]) {
    const time = Math.floor(+new Date() / 1000 / 60);
    networkErrTimes.push(time);
    // networkErrTime配列から最小時点と最大時点を取って、持続時間を求める
    const max = Math.max(...networkErrTimes);
    const min = Math.min(...networkErrTimes);
    const duration = max - min;
    // もし持続時間 >= 60分 リトライを終了します
    const isOverLimit = duration >= networkErrLimitTime;
    return { duration, min, max, isOverLimit };
  }

  private async dataAvailableUploader(
    uploadToS3: (blob: Blob, partNum: number) => Promise<AWS.S3.UploadPartOutput>,
    currPartIndex: number,
    partNumber: number,
    uploadMethodLocation: string,
    uploadMethodText: string
  ) {
    this.uploadStatus.currUploadingNum++;
    await uploadToS3(this.uploadParts[currPartIndex].data, partNumber);
    this.uploadStatus.currUploadingNum--;
    this.uploadParts[currPartIndex].data = new Blob();
    this.uploadParts[currPartIndex].Completed = true;
    logging(debugLevel, {
      module: 'dataAvailableHandler',
      location: `${uploadMethodLocation} completed`,
      details: `${uploadMethodText}のアップロードが完了 part${partNumber}`,
    });
  }

  private getRetryStatus(retry: number, uploadMethodLocation: string, uploadMethodText: string) {
    const currPartIndex = this.uploadParts.findIndex((v) => v.Completed === false);
    const partNumber = this.uploadParts[currPartIndex].PartNumber;
    // // TODO: disable this log before merge
    // console.log({
    //   currPartIndex,
    //   currUploadingNum: this.uploadStatus.currUploadingNum,
    //   Completed: this.uploadParts.map((p) => p.Completed)
    // });
    if (retry === 0) {
      logging(debugLevel, {
        module: 'dataAvailableHandler',
        location: `${uploadMethodLocation} start`,
        details: `${uploadMethodText}開始 part:${partNumber}`,
      });
    } else {
      logging(debugLevel, {
        module: 'dataAvailableHandler',
        location: `${uploadMethodLocation} retry ${retry} start`,
        details: `${uploadMethodText}リトライ開始 part:${partNumber}`,
      });
    }
    retry++;
    return { currPartIndex, partNumber, retry };
  }

  setOptions(options: optionsType): optionsType {
    try {
      logging(debugLevel, {
        module: 'setOptions',
        location: 'defaultOptions set',
        details: 'defaultOptions set',
      });
      this.defaultOptions = Object.assign(this.defaultOptions, options);
      const mimeTypes: string[] = [
        this.defaultOptions.mimeType as string,
        'video/webm;codecs=vp9',
        'video/webm;codecs=vp8',
        'video/webm;codecs=avc1',
        'video/webm',
        'video/mp4',
      ];
      this.defaultOptions.mimeType =
        mimeTypes.find((t) => {
          if (MediaRecorder.isTypeSupported(t)) return t;
          else console.log(`${t} is not Supported`);
        }) || '';
      return this.defaultOptions;
    } catch (e) {
      logging(
        debugLevel,
        {
          module: 'setOptions',
          location: 'defaultOptions error',
          details: `Exception while creating MediaRecorder: ${e.toString()}`,
        },
        true
      );
      this.eventHandler.initializeError({ error: e, errorString: e.toString() });
      throw e;
    }
  }
}

const s3Plugin: Plugin = (context: Context, inject) => {
  const s3Uploader: S3Uploader = new S3Uploader(context);
  loggerContext = context; // logging()にcontextを渡す
  inject(
    'startUploader',
    (stream: MediaStream, eventHandler: UploaderEventHandler, options: optionsType): Promise<boolean> => {
      return s3Uploader.startUploader(context, stream, eventHandler, options);
    }
  );
  inject('stopUploader', (): Promise<boolean> => {
    return s3Uploader.stopUploader();
  });
  inject('ugUploadCompleteHandler', (): Promise<void> => {
    return s3Uploader.ugUploadCompleteHandler();
  });
  inject('ugUploadGetS3Configs', (): Promise<s3ConfigsType> => {
    return s3Uploader.ugUploadGetS3Configs();
  });
};

export default s3Plugin;
