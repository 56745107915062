// 注意事項 (/examining) 関連のAPI
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { TemplateListFromAPI, TemplateItem } from './terms';
import { alertError } from './error-handling';
import { Endpoint } from '@/store/const/endpoint';
import type { Modals } from '@/plugins/global/modals.ts';

export interface ExaminingTermTemplate extends Omit<TemplateItem, 'footerURL'> {}
export interface ExaminingTermList extends TemplateListFromAPI {}

export const defaultExaminingTermTemplate: ExaminingTermTemplate = {
  templateBody: `
<p class="exp-text">
  以下の注意事項を確認し、同意の上、試験システムにログインしてください。
</p>
<div class="card mb-3">
  <div class="card-body terms-text white-space:pre-wrap; word-wrap:break-word;">
    <div>・試験開始から終了まで、席を離れることはできません。</div>
    <div>・受験中は、ヘッドフォン、イヤホンは禁止です。</div>
    <div>・試験監督からの指示がある場合は音声ではなく、画面に表示されます。</div>
    <div>・試験監督から画面上に指示があった場合は、その指示に従ってください。</div>
    <div>・受験中に試験監督から持ち込み可能物、使用可能物に関しての指摘があった場合、試験実施団体から許可されているものである場合はその旨をマイクを通じて申告してください。</div>
    <div>・受験者からの音声は、試験官は聞くことができます。</div>
    <div>・受験中に、受験者から試験官に連絡をとることはできません。</div>
    <div>・試験官は常時監視しているとは限りませんが、後日録画映像を確認することで不正の有無を確認します。</div>
    <div>・疑義があった場合など確認のため後日連絡させていただくことがあります。</div>
    <div>・試験終了時に試験官からの指示は特にありません。試験画面に終了メッセージが表示されたら、画面の案内に従い、画面を閉じて終了してください。</div>
  </div>
</div>`,
};
const { EXAMINING_TERM_LIST } = Endpoint;

export async function getExaminingTermList({
  $axios,
  $modals,
}: {
  $axios: NuxtAxiosInstance;
  $modals: Modals;
}): Promise<ExaminingTermList> {
  const defaultErrorMessage = '注意事項リストの取得に失敗しました';
  try {
    const data = await $axios.$get(EXAMINING_TERM_LIST.url);
    if (!Array.isArray(data)) throw new Error(defaultErrorMessage);
    return data;
  } catch (error) {
    alertError({ error, defaultErrorMessage, $modals });
    return [];
  }
}
