// お問い合わせ (footer) 関連のAPI
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { alertError } from './error-handling';
import type { TemplateListFromAPI, TemplateItem } from './terms';
import { Endpoint } from '@/store/const/endpoint';

export interface InquiryTemplate extends TemplateItem {}
export interface InquiryTemplateList extends TemplateListFromAPI {}

const defaultInquiryTemplate: InquiryTemplate = {
  footerURL: 'https://www.e-coms.co.jp/service/remote-testing/inquery/contact.html',
  templateBody: '',
};
const { INQUIRY_TEMPLATE, INQUIRY_TEMPLATE_LIST } = Endpoint;

export async function getInquiryTemplate({
  $axios,
  id,
}: {
  $axios: NuxtAxiosInstance;
  id: string | number;
}): Promise<InquiryTemplate> {
  const defaultErrorMessage = 'お問い合わせの取得に失敗しました';
  try {
    const data = await $axios.$get(INQUIRY_TEMPLATE.url, {
      params: { template_id: id },
    });
    const footerURL = data?.result?.template_footer_url;
    const templateBody = data?.result?.template_body;
    if (typeof footerURL === 'string' && typeof templateBody === 'string') {
      return { footerURL, templateBody };
    }
    throw new Error(defaultErrorMessage);
  } catch (error) {
    alertError({ error, defaultErrorMessage });
    return defaultInquiryTemplate;
  }
}

export async function getInquiryTemplateList({ $axios }: { $axios: NuxtAxiosInstance }): Promise<InquiryTemplateList> {
  const defaultErrorMessage = 'お問い合わせリストの取得に失敗しました';
  try {
    const data = await $axios.$get(INQUIRY_TEMPLATE_LIST.url);
    if (!Array.isArray(data)) throw new Error(defaultErrorMessage);
    return data;
  } catch (error) {
    alertError({ error, defaultErrorMessage });
    return [];
  }
}
