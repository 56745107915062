// Getters
// フォーム
export const GETTER_LOGINS_PAGE_GET_FORM_VALUES: string = 'loginsPage/GETTER_LOGINS_PAGE_GET_FORM_VALUES';
export const GETTER_LOGINS_PAGE_GET_FORM_VALUES_ACTOR_KIND: string =
  'loginsPage/GETTER_LOGINS_PAGE_GET_FORM_VALUES_ACTOR_KIND';
export const GETTER_LOGINS_PAGE_GET_FORM_VALUES_SORT: string = 'loginsPage/GETTER_LOGINS_PAGE_GET_FORM_VALUES_SORT';
export const GETTER_LOGINS_PAGE_GET_FORM_VALUES_PAGE: string = 'loginsPage/GETTER_LOGINS_PAGE_GET_FORM_VALUES_PAGE';
// テーブル
export const GETTER_LOGINS_PAGE_GET_TABLE_VALUES: string = 'loginsPage/GETTER_LOGINS_PAGE_GET_TABLE_VALUES';
export const GETTER_LOGINS_PAGE_GET_TABLE_VALUES_PAGE: string = 'loginsPage/GETTER_LOGINS_PAGE_GET_TABLE_VALUES_PAGE';
export const GETTER_LOGINS_PAGE_GET_TABLE_VALUES_PAGE_COUNT: string =
  'loginsPage/GETTER_LOGINS_PAGE_GET_TABLE_VALUES_PAGE_COUNT';
export const GETTER_LOGINS_PAGE_GET_TABLE_VALUES_COUNT: string = 'loginsPage/GETTER_LOGINS_PAGE_GET_TABLE_VALUES_COUNT';
export const GETTER_LOGINS_PAGE_GET_TABLE_VALUES_LIST: string = 'loginsPage/GETTER_LOGINS_PAGE_GET_TABLE_VALUES_LIST';

// Mutations
// フォーム
export const MUTATION_LOGINS_PAGE_CLEAR_FORM_VALUES: string = 'loginsPage/MUTATION_LOGINS_PAGE_CLEAR_FORM_VALUES';
export const MUTATION_LOGINS_PAGE_SET_FORM_VALUES: string = 'loginsPage/MUTATION_LOGINS_PAGE_SET_FORM_VALUES';
export const MUTATION_LOGINS_PAGE_SET_FORM_VALUES_ACTOR_KIND: string =
  'loginsPage/MUTATION_LOGINS_PAGE_SET_FORM_VALUES_ACTOR_KIND';
export const MUTATION_LOGINS_PAGE_SET_FORM_VALUES_SORT: string = 'loginsPage/MUTATION_LOGINS_PAGE_SET_FORM_VALUES_SORT';
export const MUTATION_LOGINS_PAGE_SET_FORM_VALUES_PAGE: string = 'loginsPage/MUTATION_LOGINS_PAGE_SET_FORM_VALUES_PAGE';
// テーブル
export const MUTATION_LOGINS_PAGE_CLEAR_TABLE_VALUES: string = 'loginsPage/MUTATION_LOGINS_PAGE_CLEAR_TABLE_VALUES';
export const MUTATION_LOGINS_PAGE_SET_TABLE_VALUES: string = 'loginsPage/MUTATION_LOGINS_PAGE_SET_TABLE_VALUES';

// Actions
// フォーム
export const ACTION_LOGINS_PAGE_CLEAR_FORM_VALUES: string = 'loginsPage/ACTION_LOGINS_PAGE_CLEAR_FORM_VALUES';
export const ACTION_LOGINS_PAGE_SET_FORM_VALUES: string = 'loginsPage/ACTION_LOGINS_PAGE_SET_FORM_VALUES';
export const ACTION_LOGINS_PAGE_SET_FORM_VALUES_ACTOR_KIND: string =
  'loginsPage/ACTION_LOGINS_PAGE_SET_FORM_VALUES_ACTOR_KIND';
export const ACTION_LOGINS_PAGE_SET_FORM_VALUES_SORT: string = 'loginsPage/ACTION_LOGINS_PAGE_SET_FORM_VALUES_SORT';
export const ACTION_LOGINS_PAGE_SET_FORM_VALUES_PAGE: string = 'loginsPage/ACTION_LOGINS_PAGE_SET_FORM_VALUES_PAGE';
// テーブル
export const ACTION_LOGINS_PAGE_CLEAR_TABLE_VALUES: string = 'loginsPage/ACTION_LOGINS_PAGE_CLEAR_TABLE_VALUES';
export const ACTION_LOGINS_PAGE_SET_TABLE_VALUES: string = 'loginsPage/ACTION_LOGINS_PAGE_SET_TABLE_VALUES';
// API
export const ACTION_LOGINS_PAGE_CALL_API_GET_LOGINS: string = 'loginsPage/ACTION_LOGINS_PAGE_CALL_API_GET_LOGINS';
