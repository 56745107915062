






























import Vue from 'vue';
import StartupAdapter from '../../store/types/adapters/startupAdapter';
import * as rootTypes from '@/store/types/rootType';
import { LanguageEnum } from '~/store/enum/language';
import { getTermsOfUse } from '@/server/api/v1/terms';
import { getPrivacyPolicy } from '@/server/api/v1/privacy';
import { getInquiryTemplate } from '@/server/api/v1/inquiry';
import { isSameURL, isLocalLink } from '@/utils/string-tools';

type FooterLinkName = 'terms' | 'privacy' | 'inquiry';

export default Vue.extend({
  name: 'Footer',

  data() {
    return {
      termsOfUseLink: '',
      privacyLink: '',
      inquiryLink: '',
    };
  },

  computed: {
    inParams(): StartupAdapter {
      return this.$store.getters[rootTypes.GETTER_STARTUP];
    },
    displayLang(): LanguageEnum {
      return this.$store.getters[rootTypes.GETTER_DISPLAY_LANG];
    },
  },

  created() {
    const { termsOfServiceTemplate: tID, privacyPolicyTemplate: pID, inquiryTemplate: iID } = this.inParams;
    const { $axios, $modals } = this;
    if (this.isExamee()) {
      getTermsOfUse({ $axios, $modals, id: tID }).then(({ footerURL }) => (this.termsOfUseLink = footerURL));
      getPrivacyPolicy({ $axios, $modals, id: pID }).then(({ footerURL }) => (this.privacyLink = footerURL));
    }
    getInquiryTemplate({ $axios, id: iID }).then(({ footerURL }) => (this.inquiryLink = footerURL));
  },

  methods: {
    isExamee() {
      // TODO: Enum
      return this.inParams.actor === 1; // FIXME: 受験者のみ
    },

    onClickLink(event: MouseEvent, name: FooterLinkName, link?: string) {
      event.preventDefault();

      if (!link) link = `/${name}`;

      // if link is the same with current page, do nothing
      if (isSameURL(this.$route.path, link)) return;

      if (isLocalLink(link)) {
        const route = this.$router.resolve({ name });
        window.open(route.href + '/');
      } else {
        window.open(link);
      }
    },
  },
});
