import { Plugin, Context } from '@nuxt/types';
import * as rootTypes from '@/store/types/rootType';
import { Formatter } from '@/utils/Formatter';

declare module 'vue/types/vue' {
  interface Vue {
    $logging(logLevel: number, path: string, details: string): void;
  }
}

const loggerPlugin: Plugin = (context: Context, inject) => {
  inject('logging', (logLevel: number, path: string, details: string) => {
    try {
      const _context: any = context;
      const _isDebug = _context.app.store.getters[rootTypes.GETTER_STARTUP].isDebug;
      if (_isDebug >= logLevel) {
        const endTime = new Date();
        const params: any = {
          type: 5,
          path,
          timestamp: Formatter.date('yyyy-MM-dd hh:mm:ss', endTime),
          module: 'logger',
          location: 'front',
          details,
        };
        console.log(params);
        context.app.$axios.$post('/api/v1/log_receive.php', params);
      }
    } catch (e) {
      // ここのエラーは諦める
    }
  });
};

export default loggerPlugin;
