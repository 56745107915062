// Getters
// 検索条件の選択肢
export const GETTER_COMMON_EXAMINEES_GET_TESTERS_CONDITIONS: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_TESTERS_CONDITIONS';
// テスター検索フォーム
export const GETTER_COMMON_EXAMINEES_GET_FORM_VALUES: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_FORM_VALUES';
export const GETTER_COMMON_EXAMINEES_GET_FORM_VALUES_FOR_DOWNLOAD: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_FORM_VALUES_FOR_DOWNLOAD';
export const GETTER_COMMON_EXAMINEES_GET_GROUP: string = 'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_GROUP';
export const GETTER_COMMON_EXAMINEES_GET_GROUP_CODE: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_GROUP_CODE';
export const GETTER_COMMON_EXAMINEES_GET_LOGIN_ID: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_LOGIN_ID';
export const GETTER_COMMON_EXAMINEES_GET_EXAM_NAME: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_EXAM_NAME';
export const GETTER_COMMON_EXAMINEES_GET_IS_LIKE_SEARCH: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_IS_LIKE_SEARCH';
export const GETTER_COMMON_EXAMINEES_GET_TEST_NAME: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_TEST_NAME';
export const GETTER_COMMON_EXAMINEES_GET_REGION: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_REGION';
export const GETTER_COMMON_EXAMINEES_GET_TEST_AT_DATE_FROM: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_TEST_AT_DATE_FROM';
export const GETTER_COMMON_EXAMINEES_GET_TEST_AT_DATE_TO: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_TEST_AT_DATE_TO';
export const GETTER_COMMON_EXAMINEES_GET_TEST_AT_TIME_FROM: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_TEST_AT_TIME_FROM';
export const GETTER_COMMON_EXAMINEES_GET_TEST_AT_TIME_TO: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_TEST_AT_TIME_TO';
export const GETTER_COMMON_EXAMINEES_GET_RECORD: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_RECORD';
export const GETTER_COMMON_EXAMINEES_GET_WITH_MARK: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_WITH_MARK';
export const GETTER_COMMON_EXAMINEES_GET_CHEATING_LEVEL_FROM: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_CHEATING_LEVEL_FROM';
export const GETTER_COMMON_EXAMINEES_GET_CHEATING_LEVEL_TO: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_CHEATING_LEVEL_TO';
export const GETTER_COMMON_EXAMINEES_GET_SCORE: string = 'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_SCORE';
export const GETTER_COMMON_EXAMINEES_GET_AI_ANALYSIS_FLAG: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_AI_ANALYSIS_FLAG';
export const GETTER_COMMON_EXAMINEES_GET_AI_ANALYSIS_BATCH_FLAG: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_AI_ANALYSIS_BATCH_FLAG';
export const GETTER_COMMON_EXAMINEES_GET_AI_NAME_MATCH: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_AI_NAME_MATCH';
export const GETTER_COMMON_EXAMINEES_GET_MARK_ID: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_MARK_ID';
export const GETTER_COMMON_EXAMINEES_GET_SORT_KEY: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_SORT_KEY';
export const GETTER_COMMON_EXAMINEES_GET_SORT_ORDER: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_SORT_ORDER';
export const GETTER_COMMON_EXAMINEES_GET_PAGE: string = 'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_PAGE';
// テスター検索結果
export const GETTER_COMMON_EXAMINEES_GET_PAGE_COUNT: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_PAGE_COUNT';
export const GETTER_COMMON_EXAMINEES_GET_COUNT: string = 'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_COUNT';
export const GETTER_COMMON_EXAMINEES_GET_NORMAL_TESTERS: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_NORMAL_TESTERS';
export const GETTER_COMMON_EXAMINEES_GET_SELECTABLE_TESTERS: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_SELECTABLE_TESTERS';
export const GETTER_COMMON_EXAMINEES_GET_SELECTED_TESTERS: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_SELECTED_TESTERS';
export const GETTER_COMMON_EXAMINEES_GET_HEADER_SELECTED: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_GET_HEADER_SELECTED';

// Mutations
// 検索条件の選択肢
export const MUTATION_COMMON_EXAMINEES_SET_TESTERS_CONDITIONS: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_TESTERS_CONDITIONS';
// テスター検索フォーム
export const MUTATION_COMMON_EXAMINEES_CLEAR_FORM_VALUES: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_CLEAR_FORM_VALUES';
export const MUTATION_COMMON_EXAMINEES_SET_FORM_VALUES_FOR_DOWNLOAD: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_FORM_VALURS_FOR_DOWNLOAD';
export const MUTATION_COMMON_EXAMINEES_SET_GROUP: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_GROUP';
export const MUTATION_COMMON_EXAMINEES_SET_GROUP_CODE: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_GROUP_CODE';
export const MUTATION_COMMON_EXAMINEES_SET_LOGIN_ID: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_LOGIN_ID';
export const MUTATION_COMMON_EXAMINEES_SET_EXAM_NAME: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_EXAM_NAME';
export const MUTATION_COMMON_EXAMINEES_SET_IS_LIKE_SEARCH: string =
  'commonExamineesConditions/GETTER_COMMON_EXAMINEES_SET_IS_LIKE_SEARCH';
export const MUTATION_COMMON_EXAMINEES_SET_TEST_NAME: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_TEST_NAME';
export const MUTATION_COMMON_EXAMINEES_SET_REGION: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_REGION';
export const MUTATION_COMMON_EXAMINEES_SET_TEST_AT_DATE_FROM: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_TEST_AT_DATE_FROM';
export const MUTATION_COMMON_EXAMINEES_SET_TEST_AT_DATE_TO: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_TEST_AT_DATE_TO';
export const MUTATION_COMMON_EXAMINEES_SET_TEST_AT_TIME_FROM: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_TEST_AT_TIME_FROM';
export const MUTATION_COMMON_EXAMINEES_SET_TEST_AT_TIME_TO: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_TEST_AT_TIME_TO';
export const MUTATION_COMMON_EXAMINEES_SET_RECORD: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_RECORD';
export const MUTATION_COMMON_EXAMINEES_SET_WITH_MARK: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_WITH_MARK';
export const MUTATION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_FROM: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_FROM';
export const MUTATION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_TO: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_TO';
export const MUTATION_COMMON_EXAMINEES_SET_SCORE: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_SCORE';
export const MUTATION_COMMON_EXAMINEES_SET_AI_ANALYSIS_FLAG: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_AI_ANALYSIS_FLAG';
export const MUTATION_COMMON_EXAMINEES_SET_AI_ANALYSIS_BATCH_FLAG: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_AI_ANALYSIS_BATCH_FLAG';
export const MUTATION_COMMON_EXAMINEES_SET_AI_NAME_MATCH: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_AI_NAME_MATCH';
export const MUTATION_COMMON_EXAMINEES_SET_MARK_ID: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_MARK_ID';
export const MUTATION_COMMON_EXAMINEES_SET_SORT_KEY: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_SORT_KEY';
export const MUTATION_COMMON_EXAMINEES_SET_SORT_ORDER: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_SORT_ORDER';
export const MUTATION_COMMON_EXAMINEES_SET_PAGE: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_PAGE';
// テスター検索結果
export const MUTATION_COMMON_EXAMINEES_SET_PAGE_COUNT: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_PAGE_COUNT';
export const MUTATION_COMMON_EXAMINEES_SET_COUNT: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_COUNT';
export const MUTATION_COMMON_EXAMINEES_SET_TESTERS: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_TESTERS';
export const MUTATION_COMMON_EXAMINEES_SET_SELECTED_OF_TESTERS: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_SELECTED_OF_TESTERS';
export const MUTATION_COMMON_EXAMINEES_SET_HEADER_SELECTED: string =
  'commonExamineesConditions/MUTATION_COMMON_EXAMINEES_SET_HEADER_SELECTED';

// Actions
// 検索条件の選択肢
export const ACTION_COMMON_EXAMINEES_GET_TESTERS_CONDITIONS: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_GET_TESTERS_CONDITIONS';
// テスター検索フォーム
export const ACTION_COMMON_EXAMINEES_CLEAR_FORM_VALUES: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_CLEAR_FORM_VALURS';
export const ACTION_COMMON_EXAMINEES_SET_GROUP: string = 'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_GROUP';
export const ACTION_COMMON_EXAMINEES_SET_GROUP_CODE: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_GROUP_CODE';
export const ACTION_COMMON_EXAMINEES_SET_LOGIN_ID: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_LOGIN_ID';
export const ACTION_COMMON_EXAMINEES_SET_EXAM_NAME: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_EXAM_NAME';
export const ACTION_COMMON_EXAMINEES_SET_IS_LIKE_SEARCH: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_IS_LIKE_SEARCH';
export const ACTION_COMMON_EXAMINEES_SET_TEST_NAME: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_TEST_NAME';
export const ACTION_COMMON_EXAMINEES_SET_REGION: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_REGION';
export const ACTION_COMMON_EXAMINEES_SET_TEST_AT_DATE_FROM: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_TEST_AT_DATE_FROM';
export const ACTION_COMMON_EXAMINEES_SET_TEST_AT_DATE_TO: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_TEST_AT_DATE_TO';
export const ACTION_COMMON_EXAMINEES_SET_TEST_AT_TIME_FROM: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_TEST_AT_TIME_FROM';
export const ACTION_COMMON_EXAMINEES_SET_TEST_AT_TIME_TO: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_TEST_AT_TIME_TO';
export const ACTION_COMMON_EXAMINEES_SET_RECORD: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_RECORD';
export const ACTION_COMMON_EXAMINEES_SET_WITH_MARK: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_WITH_MARK';
export const ACTION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_FROM: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_FROM';
export const ACTION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_TO: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_CHEATING_LEVEL_TO';
export const ACTION_COMMON_EXAMINEES_SET_SCORE: string = 'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_SCORE';
export const ACTION_COMMON_EXAMINEES_SET_AI_ANALYSIS_FLAG: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_AI_ANALYSIS_FLAG';
export const ACTION_COMMON_EXAMINEES_SET_AI_ANALYSIS_BATCH_FLAG: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_AI_ANALYSIS_BATCH_FLAG';
export const ACTION_COMMON_EXAMINEES_SET_AI_NAME_MATCH: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_AI_NAME_MATCH';
export const ACTION_COMMON_EXAMINEES_SET_MARK_ID: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_MARK_ID';
export const ACTION_COMMON_EXAMINEES_SET_SORT_KEY: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_SORT_KEY';
export const ACTION_COMMON_EXAMINEES_SET_SORT_ORDER: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_SORT_ORDER';
export const ACTION_COMMON_EXAMINEES_SET_PAGE: string = 'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_PAGE';
// テスター検索結果
export const ACTION_COMMON_EXAMINEES_GET_TESTERS: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_GET_TESTERS';
export const ACTION_COMMON_EXAMINEES_DOWNLOAD_TESTERS: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_DOWNLOAD_TESTERS';
export const ACTION_COMMON_EXAMINEES_SET_SELECTED_OF_TESTERS: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_SELECTED_OF_TESTERS';
export const ACTION_COMMON_EXAMINEES_SET_HEADER_SELECTED: string =
  'commonExamineesConditions/ACTION_COMMON_EXAMINEES_SET_HEADER_SELECTED';
