// Getters
export const GETTER_MONITOR_PAGE: string = 'monitorPage/GETTER_MONITOR_PAGE';
export const GETTER_MATCHING_HISTORIES: string = 'monitorPage/GETTER_MATCHING_HISTORIES';

// Mutations
export const MUTATION_MONITOR_PAGE_SET_IN_AUTH: string = 'monitorPage/MUTATION_MONITOR_PAGE_SET_IN_AUTH';
export const MUTATION_MONITOR_PAGE_SET_MATCHING_HISTORIES: string =
  'monitorPage/MUTATION_MONITOR_PAGE_SET_MATCHING_HISTORIES';

// Actions
export const ACTION_MONITOR_PAGE: string = 'monitorPage/ACTION_MONITOR_PAGE';
export const ACTION_MONITOR_PAGE_MARKS: string = 'monitorPage/ACTION_MONITOR_PAGE_MARKS';
export const ACTION_MONITOR_PAGE_NOTIFY_LIST: string = 'monitorPage/ACTION_MONITOR_PAGE_NOTIFY_LIST';
export const ACTION_MONITOR_PAGE_GET_TESTER: string = 'monitorPage/ACTION_MONITOR_PAGE_GET_TESTER';
export const ACTION_MONITOR_PAGE_RECORD_MARKING: string = 'monitorPage/ACTION_MONITOR_PAGE_RECORD_MARKING';
export const ACTION_MONITOR_PAGE_TESTER_KICK_OUT: string = 'monitorPage/ACTION_MONITOR_PAGE_TESTER_KICK_OUT';
export const ACTION_MONITOR_PAGE_ACCEPT: string = 'monitorPage/ACTION_MONITOR_PAGE_ACCEPT';
export const ACTION_MONITOR_PAGE_SET_IN_AUTH: string = 'monitorPage/ACTION_MONITOR_PAGE_SET_IN_AUTH';
export const ACTION_MONITOR_PAGE_REJECT: string = 'monitorPage/ACTION_MONITOR_PAGE_REJECT';
export const ACTION_MONITOR_PAGE_GET_MATCHING_HISTORIES: string =
  'monitorPage/ACTION_MONITOR_PAGE_GET_MATCHING_HISTORIES';
