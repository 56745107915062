// 利用規約 (footer) 関連のAPI
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { alertError } from './error-handling';
import type { Modals } from '@/plugins/global/modals.ts';
import { Endpoint } from '@/store/const/endpoint';
import defaultTemplateText from '@/store/const/ja/ENV_SETTING_TERM_OF_USE_TEXT';
import { getStrWithHtmlNewLine } from '@/utils/string-tools';

export interface TemplateItem {
  footerURL: string;
  templateBody: string;
}
export interface TermsOfUse extends TemplateItem {}

export interface TemplateListFromAPI {
  [index: number]: { id: number; template_name: string };
}
export interface TermsOfUseList extends TemplateListFromAPI {}

const defaultTermsOfUse: TermsOfUse = {
  footerURL: '/terms',
  templateBody: getStrWithHtmlNewLine(defaultTemplateText),
};
const { TERMS_OF_SERVICE, TERMS_OF_SERVICE_LIST } = Endpoint;

export async function getTermsOfUse({
  $axios,
  $modals,
  id,
}: {
  $axios: NuxtAxiosInstance;
  $modals: Modals;
  id: string | number;
}): Promise<TermsOfUse> {
  const defaultErrorMessage = `利用規約 (footer) の取得に失敗しました。`;
  try {
    const data = await $axios.$get(TERMS_OF_SERVICE.url, {
      params: { template_id: id },
    });
    const footerURL = data?.result?.template_footer_url;
    const templateBody = data?.result?.template_body;
    if (typeof footerURL === 'string' && typeof templateBody === 'string') {
      return { footerURL, templateBody };
    }
    throw new Error(defaultErrorMessage);
  } catch (error) {
    alertError({ error, defaultErrorMessage, $modals });
    return defaultTermsOfUse;
  }
}

export async function getTermsOfUseList({
  $axios,
  $modals,
}: {
  $axios: NuxtAxiosInstance;
  $modals: Modals;
}): Promise<TermsOfUseList> {
  const defaultErrorMessage = '利用規約リスト (footer) の取得に失敗しました';
  try {
    const data = await $axios.$get(TERMS_OF_SERVICE_LIST.url);
    if (!Array.isArray(data)) throw new Error(defaultErrorMessage);
    return data;
  } catch (error) {
    alertError({ error, defaultErrorMessage, $modals });
    return [];
  }
}
